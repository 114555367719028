import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import COLORS from "../../constant/colors";

export const StyledBox = styled(Box)({
  padding: "16px",
  backgroundColor: "#f5f5f5",
  marginBottom: "16px",
});

export const NumberCircle = styled(Box)({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",
});

export const SectionHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#000",
  color: "#fff",
  padding: "8px",
});

export const StyledTypography = styled(Typography)({
  fontSize: "18px",
  color: "#fff",
  marginLeft: "8px",
});

export const textFieldStyles = (direction = "ltr") => ({
  borderRadius: "4px",
  "& label": {
    transformOrigin: direction === "rtl" ? "right !important" : "unset",
    left: direction === "rtl" ? "inherit !important" : "unset",
    right: direction === "rtl" ? "1.75rem !important" : "unset",
    fontSize: "small",
    color: "#000000",
    fontWeight: 400,
    overflow: "unset",
  },
  "& .MuiInputLabel-root": {
    fontSize: "small",
    color: "#000000",
    fontWeight: 400,
  },
  "& .MuiFormHelperText-root": {
    mx: "0px",
  },
  "& .MuiSelect-icon": {
    left: direction === "rtl" ? "7px" : "unset",
    right: direction === "rtl" ? "unset" : "7px",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: COLORS.WHITE,
    fontWeight: 400,
    lineHeight: "17.34px",
    "& legend": {
      textAlign: direction === "rtl" ? "right" : "left",
    },
  },
});
