import React, { useCallback, useEffect, useMemo } from "react";
import { Grid, Box, CardMedia, ButtonBase, IconButton } from "@mui/material";
import {
  KeyboardBackspaceRounded,
  CloseOutlined,
  ZoomInOutlined,
} from "@mui/icons-material";
import Carousel, { StateCallBack } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { string } from "yargs";

interface GalleryContentProps {
  sliderRef: React.RefObject<Carousel>;
  thumbnailContainerRef: React.RefObject<HTMLDivElement>;
  afterChange?: (e: any) => void;
  galleryImage: string[];
  dir: "ltr" | "rtl";
  isRTL: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  navigate: (path: number) => void;
  currentIndex?: number;
}

const GalleryContent: React.FC<GalleryContentProps> = ({
  sliderRef,
  thumbnailContainerRef,
  afterChange,
  galleryImage = [],
  dir,
  isRTL,
  isOpen,
  setIsOpen,
  navigate,
  currentIndex,
}) => {
  const handleThumbnailClick = useCallback(
    (index: number) => {
      sliderRef.current?.goToSlide(index);
    },
    [sliderRef]
  );

  const thumbnails = useMemo(
    () =>
      galleryImage.map((imgURL, index) => (
        <Grid
          item
          key={index}
          sx={{
            flexDirection: "column",
            mb: { xs: 0, sm: "10px" },
          }}
        >
          <ButtonBase onClick={() => handleThumbnailClick(index)}>
            <CardMedia
              draggable={false}
              component="img"
              // width="100%"
              style={{
                height: "100%",
                left: "0",
                objectFit: "cover",
                width: "100%",
              }}
              image={imgURL}
              alt="work portfolio"
              sx={{
                // cursor: "pointer", width: {
                //   xs: '187px', // w-8
                //   sm: '187px', // sm:w-10
                //   md: '187px', // md:w-12
                //   lg: '187px', // lg:w-14
                //   xl: '200px', // xl:w-14
                // },
                objectFit: "cover",
              }}
            />
          </ButtonBase>
        </Grid>
      )),
    [galleryImage, handleThumbnailClick]
  );

  const handleSlideClick = useCallback((index: number) => {
    console.log(index, "slider");
  }, []);

  const handleToggleZoom = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (currentIndex !== undefined) {
      if (isOpen && sliderRef.current) {
        sliderRef.current.goToSlide(currentIndex);
      }
    }
  }, [isOpen, currentIndex, sliderRef]);

  const CustomDot = ({ onClick, active }: any) => {
    return (
      <li
        className="d-block d-sm-none"
        style={{
          margin: "0 3px",
          cursor: "pointer",
        }}
      >
        {/* <button
          onClick={onClick}
          style={{
            background: active ? "#000" : "#A4A1AA33",
            borderRadius: "6px",
            width: "12px",
            height: "12px",
            border: "none",
            WebkitAppearance: "none",
          }}
        /> */}
        <div
          onClick={onClick}
          style={{
            background: active ? "#000" : "#A4A1AA33",
            borderRadius: "6px",
            width: "12px",
            height: "12px",
            display: "inline-block",
            border: "none",
            WebkitAppearance: "none",
            cursor: "pointer",
          }}
        />
      </li>
    );
  };

  const memoizedCarousel = useMemo(
    () => (
      <Carousel
        beforeChange={afterChange}
        ref={sliderRef}
        customDot={<CustomDot />}
        additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={50}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={dir === "rtl"}
        shouldResetAutoplay
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {galleryImage.map((imgURL, index) => (
          <div key={index} onClick={() => handleSlideClick(index)}>
            <CardMedia
              draggable={false}
              component="img"
              width="100%"
              image={imgURL}
              sx={{
                cursor: isOpen ? "zoom-out" : "zoom-in",
                minHeight: { xs: "450px", sm: "600px" },
              }}
            />
          </div>
        ))}
      </Carousel>
    ),
    [sliderRef, galleryImage, handleSlideClick, dir, isOpen]
  );

  return (
    <Grid container sx={{ mt: "3px" }}>
      <Grid item xs={12} sm={2.5}>
        <Box
          component="div"
          flexDirection="column"
          sx={{
            overflowY: "auto",
            flexWrap: "nowrap",
            width: "90%",
            height: "72%",
            marginRight: dir === "rtl" ? "0" : "10px",
            marginLeft: dir === "rtl" ? "10px" : "0",
            display: { xs: "none", sm: "flex" },
            "&::-webkit-scrollbar": { display: "none" },
          }}
          ref={thumbnailContainerRef}
        >
          {thumbnails}
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          "& .react-multi-carousel-list.container": { paddingX: "0px" },
        }}
        xs={12}
        sm={9}
        spacing={2}
      >
        <IconButton
          className="rtl-transform"
          size="small"
          sx={{
            position: "fixed",
            [isRTL ? "right" : "left"]: "1px",
            display: { xs: "flex", sm: "none" },
            color: "#000000",
            zIndex: 2,
            width: "17px",
          }}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceRounded fontSize="small" />
        </IconButton>
        <Box
          sx={{
            position: "relative",
            zIndex: 1000,
            // left: !isRTL ? (isOpen ? "69.5%" : "45.5%") : null,
            // right: isRTL ? (isOpen ? "69.5%" : "45.5%") : null,
            top: isOpen ? "5%" : null,
            display: { xs: "none", sm: "flex" },
          }}
        >
          {!isOpen && (
            <IconButton
              sx={{
                position: "absolute",
                [dir === "rtl" ? "left" : "right"]: "0px",
                padding: "0px",
              }}
              color="default"
              size="small"
              aria-label="toggle zoom"
              onClick={handleToggleZoom}
            >
              <ZoomInOutlined
                style={{
                  position: "absolute",
                  width: "27px",
                  height: "30px",
                  [dir === "rtl" ? "left" : "right"]: "5px",
                  top: "0px",
                }}
              />
            </IconButton>
          )}
        </Box>
        {memoizedCarousel}
      </Grid>
    </Grid>
  );
};

export default React.memo(GalleryContent);
