import React, { useState } from "react";
import imagepath from "../../../constant/imagepath";
import {
  FormControl,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  BodyTypography,
  FormContainer,
  FormContainerWrapper,
  HeadingTypography,
  ImageContainer,
  LanguageBox,
  StyledImage,
} from "../styles/styles";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { userService } from "../../../api/services/userService";
import ShowToast from "../../../utils/ShowToast";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CustomTextField from "../../../components/TextField";
import CustomLabel from "../../../components/Label";
import CustomButton from "../../../components/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";

function ChangePassword() {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setLanguage, language } = useLanguageStore();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    navigate(newPath, { replace: true });
    // window.location.reload();
  };

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New Password is Required")
      .min(6, "New Password must be at least 6 characters"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is Required"),
  });

  const resetPassword = async (values: any) => {
    const request = {
      customer_id: id,
      password: values.password,
      confirm_password: values.confirm_password,
    };
    try {
      const response: any = await userService.resetPassword(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        // navigate(CurrentLanguagePath("login"));
        window.location.href = CurrentLanguagePath("login");
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
    }
  };

  return (
    <Grid container sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <ImageContainer item xs={12} sm={12} md={7}>
        <StyledImage src={imagepath.forgotPassword} alt="Forgot Password" />
      </ImageContainer>
      <FormContainerWrapper item xs={12} sm={12} md={5}>
        <LanguageBox dir={direction} sx={{ cursor: "pointer" }}>
          <Typography onClick={() => changeLanguage("en")}>English</Typography>{" "}
          <Typography sx={{ mx: 1 }}>|</Typography>
          <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
        </LanguageBox>
        <FormContainer>
          <HeadingTypography variant="h4">
            {t("reset_password")}
          </HeadingTypography>
          <BodyTypography variant="body1" gutterBottom>
            {t("reset_password_des")}
          </BodyTypography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => resetPassword(values)}
          >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <CustomLabel>{t("new_password")}</CustomLabel>
                  <Field
                    as={CustomTextField}
                    name="password"
                    variant="outlined"
                    fullWidth
                    placeholder={t("newPassPlaceHolder")}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <CustomLabel>{t("confirm_password")}</CustomLabel>
                  <Field
                    name="confirm_password"
                    as={CustomTextField}
                    variant="outlined"
                    fullWidth
                    placeholder={t("confirmPassPlaceHolder")}
                    onChange={handleChange}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleConfirmPasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      touched.confirm_password && !!errors.confirm_password
                    }
                    helperText={
                      touched.confirm_password && errors.confirm_password
                    }
                  />
                </FormControl>
                <CustomButton
                  onClick={() => handleSubmit()}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 4,
                    textTransform: "capitalize",
                    borderRadius: "0px",
                  }}
                >
                  {t("save")}
                </CustomButton>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </FormContainerWrapper>
    </Grid>
  );
}

export default ChangePassword;
