import { useEffect, useState } from "react";
import { homeService } from "../../../api/services/homeService";
import { FAQItem } from "../../../utils/type";
import HtmlParser from "react-html-parser";
import { Container, Typography } from "@mui/material";
import { useLanguageStore } from "../../../zustand/store/language";

function ShippingPolicy() {
  const [shippingPolicyData, setShippingPolicyData] = useState<FAQItem | null>(
    null
  );
  const { language } = useLanguageStore();

  const getPage = async () => {
    const request = {
      slug: "shipping-policy",
    };
    try {
      const response = await homeService.getPages(request);
      if (response && response.status === 200) {
        setShippingPolicyData(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPage();
  }, [language]);

  if (!shippingPolicyData)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flex: 1,
        }}
      ></div>
    );

  return (
    <div style={{ minHeight: "100vh", padding: "20px" }}>
      <Container maxWidth={"lg"}>
        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
          {shippingPolicyData.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {HtmlParser(shippingPolicyData.description)}
        </Typography>
      </Container>
    </div>
  );
}

export default ShippingPolicy;
