import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import imagepath from "../../../constant/imagepath";
import {
  BodyTypography,
  FormContainer,
  FormContainerWrapper,
  HeadingTypography,
  ImageContainer,
  LanguageBox,
  Logo,
  StyledImage,
} from "../styles/styles";
import CustomTextField from "../../../components/TextField";
import CustomLabel from "../../../components/Label";
import CustomButton from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { userService } from "../../../api/services/userService";
import ShowToast from "../../../utils/ShowToast";
import dayjs from "dayjs";
import useAuthStore from "../../../zustand/store/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";

function Signup() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "First Name must be at most 50 characters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Last Name must be at most 50 characters"),
    phone: Yup.string()
      .matches(/^5\d{8}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
        "Invalid email address"
      ),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    dob: Yup.date().required("Date of Birth is required"),
    agreeTerms: Yup.boolean().oneOf([true], "Must accept Terms and Conditions"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (customerId) {
      const path = CurrentLanguagePath(`mobile-otp?id=${customerId}`);
      console.log("Navigating to:", path);
      navigate(path);
      setCustomerId(null);
    }
  }, [customerId, navigate]);

  const handleSignUp = async (values: any) => {
    const request = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: `${values.email}`.toLocaleLowerCase(),
      country_code: values.country_code,
      password: values.password,
      dob: values.dob,
      phone: values.phone,
    };
    try {
      const response: any = await userService.signUp(request);
      const { data } = response;
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setCustomerId(response.data.id);
        useAuthStore.getState().setUserInfo(data, false);
        // setTimeout(() => {
        //   navigate(CurrentLanguagePath(`mobile-otp?id=${response.data.id}`));
        // }, 100)
      } else {
        console.log("error message:-", response.data);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const { setLanguage, language } = useLanguageStore();
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    navigate(newPath, { replace: true });
    // window.location.reload();
  };

  return (
    <Grid container sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <ImageContainer item xs={12} sm={12} md={7}>
        <StyledImage src={imagepath.signup} alt="SignUp" />
        <Logo
          src={imagepath.logo}
          alt="Logo"
          dir={direction}
          onClick={() => navigate(CurrentLanguagePath(""))}
        />
      </ImageContainer>
      <FormContainerWrapper xs={12} sm={12} md={5}>
        <LanguageBox dir={direction} sx={{ cursor: "pointer" }}>
          <Typography onClick={() => changeLanguage("en")}>English</Typography>{" "}
          <Typography sx={{ mx: 1 }}>|</Typography>
          <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
        </LanguageBox>

        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            dob: "",
            agreeTerms: false,
            country_code: "+966",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
          validateOnChange
        >
          {({ errors, touched, setFieldValue, values, onChange }: any) => (
            <FormContainer component={Form}>
              <HeadingTypography variant="h5">
                {t("newAccount")}
              </HeadingTypography>
              <BodyTypography variant="body1" gutterBottom>
                {t("regDescription")}
              </BodyTypography>

              <FormControl fullWidth>
                <CustomLabel>{t("firstName")}</CustomLabel>
                <Field
                  as={CustomTextField}
                  name="first_name"
                  variant="outlined"
                  fullWidth
                  placeholder={t("firstNamePlaceHolder")}
                  error={touched.first_name && !!errors.first_name}
                  helperText={touched.first_name && errors.first_name}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomLabel>{t("lastName")}</CustomLabel>
                <Field
                  as={CustomTextField}
                  name="last_name"
                  variant="outlined"
                  fullWidth
                  placeholder={t("lastNamePlaceHolder")}
                  error={touched.last_name && !!errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </FormControl>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={3}>
                  <FormControl fullWidth>
                    <CustomLabel>{t("countryCode")}</CustomLabel>
                    <Field
                      as={CustomTextField}
                      name="country_code"
                      variant="outlined"
                      fullWidth
                      disabled
                      error={touched.country_code && !!errors.country_code}
                      helperText={touched.country_code && errors.country_code}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src="https://vogacloset.com/3.0.28/static/media/sa.svg"
                              alt="Dubai Flag"
                              sx={{ width: "20px", height: "auto" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          paddingLeft: "8px",
                        },
                        "& .MuiInputAdornment-root": {
                          marginRight: language === "ar" ? "none" : "0px",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                        "& .MuiInputBase-root.Mui-disabled": {
                          "& > fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={9}>
                  <FormControl fullWidth>
                    <CustomLabel>{t("mobile")}</CustomLabel>
                    <Field
                      as={CustomTextField}
                      name="phone"
                      variant="outlined"
                      fullWidth
                      placeholder={"5XXXXXXXXX"}
                      onChange={(event: any) => {
                        setFieldValue(
                          "phone",
                          event.target.value.replace(/[^0-9]+/g, "")
                        );
                      }}
                      inputProps={{
                        maxLength: 9,
                        inputMode: "numeric",
                      }}
                      error={touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <CustomLabel>{t("email")}</CustomLabel>
                <Field
                  as={CustomTextField}
                  name="email"
                  variant="outlined"
                  fullWidth
                  placeholder={t("emailPlaceHolder")}
                  type="email"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomLabel>{t("password")}</CustomLabel>
                <Field
                  as={CustomTextField}
                  name="password"
                  variant="outlined"
                  fullWidth
                  placeholder={t("passPlaceHolder")}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomLabel>{t("dob")}</CustomLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={values.dob ? dayjs(values.dob) : null}
                      name="dob"
                      onChange={(e) => {
                        setFieldValue(
                          "dob",
                          e ? dayjs(e).format("YYYY-MM-DD") : ""
                        );
                      }}
                      format="YYYY-MM-DD"
                      sx={{
                        width: "100%",
                        ".MuiOutlinedInput-root": {
                          borderRadius: "2px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#131118",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#131118",
                          },
                        },
                        ".MuiInputBase-input": {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {touched.dob && errors.dob && (
                  <Typography color="error" variant="body2">
                    {errors.dob}
                  </Typography>
                )}
              </FormControl>
              <FormControlLabel
                control={
                  <Field as={Checkbox} name="agreeTerms" color="default" />
                }
                label={
                  <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                    {t("i_agree")}{" "}
                    <span style={{ fontSize: "16px", fontWeight: "700" }}>
                      <a
                        href={CurrentLanguagePath("terms-and-conditions")}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#000", textDecoration: "none" }}
                      >
                        {t("t&c")}
                      </a>
                    </span>
                  </Typography>
                }
                sx={{
                  mt: 1,
                  marginRight: "0px",
                }}
              />
              {touched.agreeTerms && errors.agreeTerms && (
                <Typography color="error" variant="body2">
                  {errors.agreeTerms}
                </Typography>
              )}
              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 4,
                  textTransform: "capitalize",
                  borderRadius: "0px",
                }}
              >
                {t("signup")}
              </CustomButton>
            </FormContainer>
          )}
        </Formik>
      </FormContainerWrapper>
    </Grid>
  );
}

export default Signup;
