import React from "react";
import {
  Drawer,
  Typography,
  Box,
  styled,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  CssBaseline,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import COLORS from "../constant/colors";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { CartData } from "../utils/type";
import { CurrentLanguagePath } from "../utils/helper";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: COLORS.WHITE,
  boxShadow: "0px 2px 10px 5px #A4A1AA33",
}));
const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  position: "relative",
  height: "76.08px",
}));
// const StyledImg = styled("img")(({ theme, dir }) => ({
//   width: "33px",
//   height: "40px",
//   cursor: "pointer",
//   position: "absolute",
//   right: dir === "rtl" ? "unset" : theme.spacing(1),
//   left: dir === "rtl" ? theme.spacing(1) : "unset",
//   top: "5px",
// }));

const StyledImage = styled("img")({
  width: "92px",
});

interface ShoppingBagDrawerProps {
  open: boolean;
  toggleDrawer: any;
  cartData: CartData;
}

const ShoppingBagDrawer: React.FC<ShoppingBagDrawerProps> = ({
  open,
  toggleDrawer,
  cartData,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [progress, setProgress] = useState(50);
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();
  // const marginProp = dir === "ltr" ? "marginLeft" : "marginRight";

  const handleBag = () => {
    toggleDrawer();
    navigate(CurrentLanguagePath("cart"));
  };

  const handleCheckout = () => {
    toggleDrawer();
    navigate(CurrentLanguagePath("checkout"));
  };

  return (
    <Drawer
      anchor={dir === "ltr" ? "right" : "left"}
      open={open}
      BackdropProps={{ invisible: true }}
      onClose={() => toggleDrawer()}
      elevation={0}
      PaperProps={{
        sx: {
          width: isSmallScreen ? "100%" : "100%",
          maxWidth: isSmallScreen ? "100%" : "350px",
          overflowX: "hidden",
          boxShadow: "3px 0 30px #0003",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {cartData?.cart_items?.length > 0 ? (
          <>
            <HeaderBox
              sx={{ boxShadow: "0px 2px 10px 5px #A4A1AA33", height: "83.8px" }}
            >
              <Typography
                variant="h6"
                fontSize={"16px"}
                sx={{
                  margin: "auto",
                  textAlign: "center",

                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "20.12px",
                }}
              >
                <>
                  {cartData.cart_items.length} {t("bag.items_added")} <br />
                  {t("bag.to_your_bag")}
                </>
              </Typography>
              <Close
                sx={{
                  width: "33px",
                  height: "40px",
                  cursor: "pointer",
                  position: "absolute",
                  right: dir === "rtl" ? "unset" : theme.spacing(1),
                  left: dir === "rtl" ? theme.spacing(1) : "unset",
                  top: "10px",
                }}
                onClick={() => toggleDrawer()}
              />
            </HeaderBox>
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {/* <Box mt={2} sx={{ [marginProp]: "16px" }}>
                <StyledTypography variant="body2">
                  {t("bag.add")}{" "}
                  <Box component="span" sx={{ fontWeight: "700" }}>
                    280 {t("bag.sar")}
                  </Box>{" "}
                  {t("bag.more_to_get")}{" "}
                  <Box component="span" sx={{ fontWeight: "700" }}>
                    {t("bag.free_shipping")}
                  </Box>
                  ! 😳
                </StyledTypography>
                <Box sx={{ width: "90%", mt: 1 }}>
                  <LinearProgress
                    className="rtl-transform"
                    variant="determinate"
                    value={progress}
                    sx={{
                      height: 6,
                      backgroundColor: COLORS.WHITE,
                      border: "1px solid black",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#DA8344",
                      },
                    }}
                  />
                </Box>
              </Box> */}
              {cartData?.cart_items?.map((product) => (
                <Grid
                  container
                  spacing={2}
                  key={product.product_id}
                  sx={{
                    margin: 0,
                    width: "100%",
                    alignItems: "center",
                    paddingRight: dir === "rtl" ? "15px" : "unset",
                  }}
                >
                  <Grid item xs={4}>
                    <StyledImage
                      src={product.product_image_url}
                      alt="Product Image"
                      style={{ objectFit: "cover", height: "144px" }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {product.vendor_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "13.34px",
                      }}
                    >
                      {product.product_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "14.45px",
                        // textAlign: "left",
                        marginTop: "8px",
                      }}
                    >
                      {t("bag.color")}: {product.color_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "14.45px",
                        // textAlign: "left",
                      }}
                    >
                      {t("bag.size")}: {product.size}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "14.45px",
                        // textAlign: "left",
                      }}
                    >
                      {t("bag.quantity")}: {product.quantity}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20.23px",
                          // textAlign: "left",
                          mt: 1,
                          textDecoration: product.sale_price && "line-through",
                        }}
                      >
                        {product.price} {t("bag.sar")}
                      </Typography>
                      {product.sale_price && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "20.23px",
                            // textAlign: "left",
                            mt: 1,
                            color: "#E51C1C",
                          }}
                        >
                          &nbsp; {product.sale_price} {t("bag.sar")}
                        </Typography>
                      )}
                    </Box>
                    {product.discount_label && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20.23px",
                        }}
                      >
                        {product.discount_label}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box sx={{ width: "100%", height: "20px" }} />
            <StyledBox>
              <Box
                sx={{
                  backgroundColor: "#A4A1AA33",
                  height: "34px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  padding: "0 17px",
                }}
              >
                <CssBaseline />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20.23px",
                    textAlign: "left",
                  }}
                >
                  {t("bag.total")}: {cartData?.grandtotal}&nbsp;
                  {t("bag.sar")}
                </Typography>
              </Box>
            </StyledBox>
            <Box sx={{ display: "flex" }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: 0,
                  textTransform: "capitalize",
                  width: "50%",
                  height: "50px",

                  backgroundColor: "#131118",
                  "&:hover": {
                    backgroundColor: "#131118",
                  },
                }}
                onClick={() => handleCheckout()}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    color: "#FFFFFF",
                  }}
                >
                  {t("bag.checkout")}
                </Typography>
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: 0,
                  textTransform: "capitalize",
                  width: "50%",
                  height: "50px",

                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "23.12px",
                  borderWidth: 1,
                  borderColor: "#000000",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
                onClick={() => handleBag()}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    color: "#000000",
                  }}
                >
                  {t("bag.view_bag")}
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <>
              <Typography
                variant="h6"
                style={{
                  fontSize: "20px",
                  letterSpacing: ".1em",
                  lineHeight: "30px",
                  padding: "35px 20px 15px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {t("bag.empty_bag_message")}
              </Typography>
              <Button
                onClick={toggleDrawer}
                variant="outlined"
                sx={{
                  borderRadius: 0,
                  textTransform: "capitalize",
                  backgroundColor: "#131118",
                  "&:hover": {
                    backgroundColor: "#131118",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "23.12px",
                    color: "#fff",
                  }}
                >
                  {t("common.close")}
                </Typography>
              </Button>
            </>
          </div>
        )}
      </Box>
    </Drawer>
  );
};

export default ShoppingBagDrawer;
