import React from "react";
import {
  Box,
  ButtonBase,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";

const ProductSkeleton: React.FC<{
  minWidth?: number | string;
  numberOfItems: number;
}> = ({ minWidth = "152.88px", numberOfItems }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const maxWidth = isSmallScreen ? "150px" : isMediumScreen ? "180px" : "202px";

  return (
    <div style={{ display: "flex" }}>
      {Array.from({ length: numberOfItems }).map((_, index) => {
        return (
          <ButtonBase
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              maxWidth: "202px",
              marginLeft: "8px",
              marginRight: "8px",
              borderRadius: "0px",
              pb: "15px",
              minWidth: isSmallScreen
                ? "150px"
                : isMediumScreen
                ? "180px"
                : "200px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "0",
                right: "5px",
                height: 40,
                width: 34,
              }}
            >
              <Skeleton variant="rectangular" width={34} height={40} />
            </Box>
            <div style={{ height: "249px", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </div>
            <Box sx={{ width: "100%", mt: 1 }}>
              <Skeleton width="60%" height={30} />
              <Skeleton width="80%" height={20} sx={{ mt: 1 }} />
              <Skeleton width="40%" height={20} sx={{ mt: 1 }} />
              <Skeleton width="100%" height={20} sx={{ mt: 1 }} />
            </Box>
          </ButtonBase>
        );
      })}
    </div>
  );
};

export default ProductSkeleton;
