import axiosInstance from "../apiClient";
import API from "../endpoints";

export const shippingService = {
  getCities: () => {
    return axiosInstance.get(API.CITY);
  },
  getShippingAddress: () => {
    return axiosInstance.get(API.SHIPPING_ADDRESS);
  },
  addShippingAddress: (data: any) => {
    return axiosInstance.post(API.ADD_SHIPPING_ADDRESS, data);
  },
  deleteShippingAddress: (data: any) => {
    return axiosInstance.post(API.DELETE_SHIPPING_ADDRESS, data);
  },
  updateShippingAddress: (data: any) => {
    return axiosInstance.post(API.UPDATE_SHIPPING_ADDRESS, data);
  },
  getShippingAddressDetails: (data: any) => {
    return axiosInstance.post(API.SHIPPING_ADDRESS_DETAILS, data);
  },
};
