import axiosInstance from "../apiClient";
import API from "../endpoints";

export const cartService = {
  getCartItems: (coupon: string) => {
    return axiosInstance.post(API.CART, {"coupon_code": coupon});
  },
  addToCart: (data: any) => {
    return axiosInstance.post(API.ADD_TO_CART, data);
  },
  removeCartItem: (data: any) => {
    return axiosInstance.post(API.REMOVE_CART, data);
  },
  updateCartSize: (data: any) => {
    return axiosInstance.post(API.UPDATE_CART_SIZE, data);
  },
  applyCoupon: (data: any) => {
    return axiosInstance.post(API.APPLY_COUPON, data);
  },
};
