import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";

export const ImageContainer = styled(Grid)({
  maxWidth: "100%",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "375px",
  },
});
export const StyledImage = styled("img")({
  maxWidth: "100%",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "375px",
  },
});
export const Logo = styled("img")(({ theme, dir }) => ({
  position: "absolute",
  left: dir === "rtl" ? "unset" : "49px",
  right: dir === "rtl" ? "10px" : "unset",
  width: "110px",
  height: "158px",
  cursor: "pointer",
  "@media (max-width: 600px)": {
    width: "51px",
    height: "76px",
    left: "15px",
  },
}));
export const FormContainerWrapper = styled(Grid)({
  position: "relative",
});
export const LanguageBox = styled(Box)(({ theme, dir }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: dir === "rtl" ? "unset" : "10px",
    left: dir === "rtl" ? "10px" : "unset",
  };
});
export const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "43px",
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
  color: "#A4A1AA",
  marginTop: 5,
  fontSize: "16px",
  lineHeight: "23px",
}));
export const FormContainer = styled(Box)(({ theme }) => ({
  marginLeft: "80px",
  marginTop: "160px",
  marginRight: "80px",
  "@media (max-width: 600px)": {
    margin: "50px 25px",
  },
  "@media (min-width: 600px) and (max-width: 960px)": {
    margin: "40px 40px",
  },
}));
export const BackConatiner = styled(Box)({
  display: "flex",
});
export const FormOptions = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});
