import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const ButtonsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: "90px",
  alignItems: "center",
  marginTop: "40px",
  "@media (max-width: 575px)": {
    marginTop: "20px",
  },
});

export const CustomButton = styled(Button)({
  width: "330px",
  height: "114px",
  border: "1px solid #000000",
  fontSize: "24px",
  alignSelf: "center",
  fontWeight: "300",
  borderRadius: "0px",
  pointerEvents: "none",
  cursor: "default",
  "@media (max-width: 575px)": {
    width: "160px",
    height: "40px",
    fontSize: "12px",
  },
});
