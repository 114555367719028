import { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { Container, Typography } from "@mui/material";
import { FAQItem } from "../../utils/type";
import { homeService } from "../../api/services/homeService";
import { useLanguageStore } from "../../zustand/store/language";
import { Helmet } from "react-helmet";

function AboutUs() {
  const [data, setData] = useState<FAQItem | null>(null);
  const { language } = useLanguageStore();

  const getPage = async () => {
    const request = {
      slug: "about-us",
    };
    try {
      const response = await homeService.getPages(request);
      if (response && response.status === 200) {
        setData(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPage();
  }, [language]);

  if (!data)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flex: 1,
        }}
      ></div>
    );
  return (
    <div style={{ minHeight: "100vh", padding: "20px" }}>
      <Helmet>
        <title>About Us - LUSIVE</title>
        <meta
          name="description"
          content="Learn more about our company, mission, and values."
        />
        <meta
          name="keywords"
          content="about us, company information, mission, values, e-commerce"
        />
        <link rel="canonical" href="http://mysite.com/about-us" />
      </Helmet>

      <Container maxWidth="lg">
        <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
          {data.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {HtmlParser(data.description)}
        </Typography>
      </Container>
    </div>
  );
}

export default AboutUs;
