import React, { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CurrentLanguagePath } from "../utils/helper";

interface SearchBarProps {
  onClose: () => void;
  onSearch?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    if (searchText.trim()) {
      navigate(CurrentLanguagePath(`products/search/?q=${searchText}`), {
        replace: true,
      });
      onClose && onClose();
    }
  };

  return (
    <TextField
      autoFocus
      fullWidth
      variant="standard"
      placeholder="Search..."
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            edge="end"
            style={{ color: "inherit" }}
            onClick={handleSearch}
          >
            <Search />
          </IconButton>
        ),
        sx: {
          color: "white",
        },
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          onClose();
        } else if (e.key === "Enter") {
          handleSearch();
        }
      }}
      sx={{
        borderBottom: "1px solid white",
        fontSize: "16px",
        "& .css-uhlitr-MuiInputBase-root-MuiInput-root": {
          borderBottom: "1px solid white", //
        },
        "& .MuiInputBase-input": {
          color: "white",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderBottom: "1px solid white",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderBottom: "1px solid white",
        },
        color: "white",
      }}
    />
  );
};

export default SearchBar;
