import styled from '@emotion/styled';
import { Box, Button, Divider, AppBar as muiBar } from '@mui/material';

// Styled components
export const AppBar = styled(muiBar)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderBottom: '2px solid #A4A1AA33',
}));

export const MenuBox = styled(Box)(({ theme }) => ({
    display: 'contents',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
}));

export const VerticalDivider = styled(Divider)(({ theme }) => ({
    mx: 2,
    borderRightWidth: 2,
    backgroundColor: "#000000",
    height: '20px',
    alignSelf: 'center',
}));

export const SearchButton = styled(Button)(({ theme }) => ({
    borderWidth: 0,
    textTransform: 'none',
    color: "#000000",
    fontFamily: 'Jost',
    '&.Mui-selected, &.Mui-focusVisible, &:hover': {
        border: "0px",
    },
}));
export const CenterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
}));


