import axiosInstance from "../apiClient";
import API from "../endpoints";

export const orderService = {
  getOrders: () => {
    return axiosInstance.get(API.ORDERS);
  },
  returnOrder: (data: any) => {
    return axiosInstance.post(API.RETURN, data);
  },
  getReturedItems: (data: any) => {
    return axiosInstance.post(API.SHOW_RETURNED_ITEMS, data);
  },
  getRefundReasons: () => {
    return axiosInstance.get(API.REFUND_REASON);
  },
  getOderReviewItems: (data: any) => {
    return axiosInstance.post(API.ORDER_REVIEW_ITEMS, data);
  },
  orderReview: (data: any) => {
    return axiosInstance.post(API.ORDER_REVIEW, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
