import React from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
} from "@mui/material";
import { Check, ExpandMoreTwoTone } from "@mui/icons-material";
import { t } from "i18next";

interface FilterPanelProps {
  attributes: Attribute[];
  subCategory: SubCategory[];
  sortOptions: SortOption[];
  selectedSubCategory: string[];
  selectedList: string[];
  priceRange: number[];
  discountRange: number[];
  selectedSort: string;
  onReset: () => void;
  onSelectAll: (title?: string, isSubCategory?: boolean) => void;
  onSubCategoryClick: (slug: string) => void;
  onItemClick: (id: string, title: string) => void;
  onPriceChange: (event: Event, newValue: number | number[]) => void;
  onDiscountChange: (event: Event, newValue: number | number[]) => void;
  onSortChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPriceChangeCommit: (event: Event, newValue: number[]) => void;
  onDiscountCommit: (event: Event, newValue: number[]) => void;
  maxPriceRange: number[];
}

interface SortOption {
  label: string;
  abbreviation: string;
  value: string;
}

interface Item {
  attributes_value_id: string;
  attribute_id: string;
  attribute_value: string;
}

interface Attribute {
  attribute_id: string;
  attribute_title: string;
  item: Item[];
}

interface SubCategory {
  id: number;
  slug: string;
  name: string;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  attributes,
  subCategory,
  sortOptions,
  selectedSubCategory,
  selectedList,
  priceRange,
  discountRange,
  selectedSort,
  onReset,
  onSelectAll,
  onSubCategoryClick,
  onItemClick,
  onPriceChange,
  onDiscountChange,
  onSortChange,
  onPriceChangeCommit,
  onDiscountCommit,
  maxPriceRange,
}) => {
  const drawer = (
    <div>
      <Box
        sx={{
          "@media (max-width: 320px)": {
            overflow: "hidden",
          },
        }}
      >
        <Typography fontSize={"24px"} fontWeight={"500"} className={"mt-2"}>
          {t("products.sort")}
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            flexWrap: "nowrap",
            alignItems: "flex-start",
            ".css-16wczlt-MuiFormControlLabel-root": {
              ml: 0,
            },
          }}
        >
          {sortOptions.map((option, index) => (
            <Grid className="p-0" item key={index}>
              <FormControlLabel
                control={
                  <Radio
                    value={option.value}
                    checked={selectedSort === option.abbreviation}
                    onChange={onSortChange}
                    sx={{ height: "20px", width: "20px", mt: 2 }}
                  />
                }
                label={
                  <Typography
                    variant="caption"
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                  >
                    {t(`products.${option.value}`) || option.label}
                  </Typography>
                }
                labelPlacement="bottom"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              />
            </Grid>
          ))}
        </Grid>

        <div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginY: "20px",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              {t("products.filter")}
            </Typography>
            <Button
              onClick={onReset}
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: "#A4A1AA",
                borderRadius: 0,
                height: "25px",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#000",
                  ":active": {
                    backgroundColor: "#A4A1AA",
                  },
                },
              }}
            >
              {t(`products.reset`)}
            </Button>
          </Box>
          {subCategory && subCategory.length > 0 && (
            <Accordion disableGutters elevation={0} sx={{ pl: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreTwoTone style={{ color: "#000000" }} />}
                sx={{ pl: 0, pr: 0 }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "300",
                      lineHeight: "28.9px",
                      // textAlign: "left",
                    }}
                  >
                    SubCategory
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      lineHeight: "14.9px",
                      // textAlign: "left",
                    }}
                  >
                    {t(`${selectedSubCategory?.length || "0"} Selected`)}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  mt: "0px",
                  mb: "0px",
                  padding: 0,
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <List
                  sx={{ mt: "0px", mb: "0px" }}
                  component="nav"
                  disablePadding
                  subheader={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#fff",
                        zIndex: 1,
                        py: 0.5,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={() => onSelectAll("", true)}
                        variant="contained"
                        sx={{
                          backgroundColor: "#A4A1AA",
                          height: "25px",
                          borderRadius: 0,
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#000",
                            ":active": {
                              backgroundColor: "#A4A1AA",
                            },
                          },
                        }}
                      >
                        {subCategory &&
                        subCategory.length === selectedSubCategory.length
                          ? t("products.clear")
                          : t("products.all")}
                      </Button>
                    </Box>
                  }
                >
                  {subCategory &&
                    subCategory.map((item: SubCategory, index: number) => (
                      <ListItem
                        key={index}
                        onClick={() => onSubCategoryClick(item.slug.toString())}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          // bgcolor: selectedSubCategory.includes(
                          //   item.slug.toString()
                          // )
                          //   ? "#f0f0f0"
                          //   : "transparent",
                        }}
                      >
                        <div
                          style={{
                            width: "22px",
                            height: "22px",
                            borderRadius: "50%",
                            backgroundColor: "rgb(237, 236, 238)",
                            position: "relative",
                          }}
                        >
                          {selectedSubCategory.includes(
                            item.slug.toString()
                          ) && (
                            <Check
                              sx={{
                                position: "absolute",
                                left: "3px",
                                fontSize: "15px",
                                top: "4px",
                              }}
                            />
                          )}
                        </div>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            textAlign: "unset",
                            mx: "10px",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
          {attributes &&
            attributes.map((data: any, index: number) => {
              return (
                <Accordion
                  key={index}
                  disableGutters
                  elevation={0}
                  sx={{ pl: 0 }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreTwoTone style={{ color: "#000000" }} />
                    }
                    sx={{ pl: 0, pr: 0 }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "300",
                          lineHeight: "28.9px",
                          // textAlign: "left",
                        }}
                      >
                        {t(`${data?.attribute_title}`)}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "14.9px",
                          // textAlign: "left",
                        }}
                      >
                        {t(
                          `${
                            selectedList[data?.attribute_title?.toLowerCase()]
                              ?.length || "0"
                          } Selected`
                        )}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      mt: "0px",
                      mb: "0px",
                      padding: 0,
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    <List
                      sx={{ mt: "0px", mb: "0px" }}
                      component="nav"
                      disablePadding
                      subheader={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 1,
                            py: 0.5,
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() =>
                              onSelectAll(
                                data?.attribute_title?.toLowerCase(),
                                false
                              )
                            }
                            variant="contained"
                            sx={{
                              backgroundColor: "#A4A1AA",
                              height: "25px",
                              borderRadius: 0,
                              "&:hover": {
                                color: "#fff",
                                backgroundColor: "#000",
                                ":active": {
                                  backgroundColor: "#A4A1AA",
                                },
                              },
                            }}
                          >
                            {data?.item?.length ===
                            (
                              selectedList[
                                data?.attribute_title?.toLowerCase()
                              ] || []
                            ).length
                              ? t("products.clear")
                              : t("products.all")}
                          </Button>
                        </Box>
                      }
                    >
                      {data?.attribute_title?.toLowerCase() === "price" ? (
                        <Box
                          sx={{
                            width: "85%",
                            margin: " 0 auto",
                            direction: "rtl",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: 2,
                              m: 2,
                              direction: "ltr",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {t("products.currency")}&nbsp;{priceRange[0]}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              {t("products.currency")}&nbsp;{priceRange[1]}
                            </Typography>
                          </Box>

                          {/* <Slider
                            i18nIsDynamicList
                            value={priceRange}
                            onChange={onPriceChange}
                            // onChangeCommitted={onPriceChange}
                          
                            valueLabelDisplay="auto"
                            min={0}
                            max={200}
                            sx={{
                              height: "3px",
                              mb: 2,
                              color:
                                "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                              "& .MuiSlider-track": {
                                border: "none",
                                background:
                                  "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                              },
                              "& .MuiSlider-thumb": {
                                height: "24px",
                                width: "24px",
                                backgroundColor: "rgb(255, 255, 255)",
                                border: "3px solid rgb(51, 51, 51)",
                                boxShadow: "rgba(51, 51, 51, 0.49) 1px 1px 15px",
                                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                {
                                  boxShadow: "inherit",
                                },
                                "&:before": {
                                  display: "none",
                                },
                              },
                              "& .MuiSlider-valueLabel": {
                                display: "none",
                              },
                            }}
                          /> */}
                        </Box>
                      ) : (
                        data?.item?.map((item: any, idx: number) => (
                          <ListItem
                            key={idx}
                            button
                            onClick={() =>
                              onItemClick(
                                item?.attributes_value_id,
                                data?.attribute_title
                              )
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // bgcolor: selectedList && selectedList[data?.attribute_title?.toLowerCase()]?.includes(
                              //   item?.attributes_value_id
                              // )
                              //   ? "#f0f0f0"
                              //   : "transparent",
                            }}
                          >
                            <div
                              style={{
                                width: "22px",
                                height: "22px",
                                borderRadius: "50%",
                                backgroundColor:
                                  data.attribute_title === "Color"
                                    ? item?.color_code
                                    : "rgb(237, 236, 238)",
                                position: "relative",
                              }}
                            >
                              {selectedList &&
                                selectedList[
                                  data?.attribute_title?.toLowerCase()
                                ]?.includes(
                                  item?.attributes_value_id.toString()
                                ) && (
                                  <Check
                                    sx={{
                                      position: "absolute",
                                      left: "3px",
                                      fontSize: "15px",
                                      top: "4px",
                                      // color: "#fff",
                                    }}
                                  />
                                )}
                            </div>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                textAlign: "unset",
                                mx: "10px",
                              }}
                            >
                              {item?.attribute_value}
                            </Typography>
                          </ListItem>
                        ))
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          {
            <Accordion disableGutters elevation={0} sx={{ pl: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreTwoTone style={{ color: "#000000" }} />}
                sx={{ pl: 0, pr: 0 }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    lineHeight: "28.9px",
                    textAlign: "left",
                  }}
                >
                  Price
                </Typography>
              </AccordionSummary>
              <Box
                sx={{
                  width: "85%",
                  margin: " 0 auto",
                  direction: "rtl",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    m: 2,
                    direction: "ltr",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    {t("products.currency")}&nbsp;{priceRange[0]}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    {t("products.currency")}&nbsp;{priceRange[1]}
                  </Typography>
                </Box>

                <Slider
                  value={priceRange}
                  onChange={onPriceChange}
                  onChangeCommitted={(e: any, newValue) =>
                    onPriceChangeCommit(e, newValue as number[])
                  }
                  valueLabelDisplay="auto"
                  min={Number(maxPriceRange[0]) || 0}
                  max={Number(maxPriceRange[1]) || 5000}
                  disabled={
                    Number(maxPriceRange[0]) === Number(maxPriceRange[1])
                  }
                  sx={{
                    height: "3px",
                    mb: 2,
                    color:
                      "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                    "& .MuiSlider-track": {
                      border: "none",
                      background:
                        "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                    },
                    "& .MuiSlider-thumb": {
                      height: "24px",
                      width: "24px",
                      backgroundColor: "rgb(255, 255, 255)",
                      border: "3px solid rgb(51, 51, 51)",
                      boxShadow: "rgba(51, 51, 51, 0.49) 1px 1px 15px",
                      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                        boxShadow: "inherit",
                      },
                      "&:before": {
                        display: "none",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      display: "none",
                    },
                  }}
                />
              </Box>
            </Accordion>
          }
          {
            <Accordion disableGutters elevation={0} sx={{ pl: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreTwoTone style={{ color: "#000000" }} />}
                sx={{ pl: 0, pr: 0 }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    lineHeight: "28.9px",
                    textAlign: "left",
                  }}
                >
                  Discount
                </Typography>
              </AccordionSummary>
              <Box
                sx={{
                  width: "85%",
                  margin: " 0 auto",
                  direction: "rtl",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    m: 2,
                    direction: "ltr",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    {discountRange[0]}%
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                    {discountRange[1]}%
                  </Typography>
                </Box>
                <Slider
                  i18nIsDynamicList
                  value={discountRange}
                  onChange={onDiscountChange}
                  onChangeCommitted={(e: any, newValue) =>
                    onDiscountCommit(e, newValue as number[])
                  }
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  sx={{
                    height: "3px",
                    mb: 2,
                    color:
                      "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                    "& .MuiSlider-track": {
                      border: "none",
                      background:
                        "linear-gradient(to right, rgb(233, 233, 233) 0%, rgb(186, 186, 186) 100%)",
                    },
                    "& .MuiSlider-thumb": {
                      height: "24px",
                      width: "24px",
                      backgroundColor: "rgb(255, 255, 255)",
                      border: "3px solid rgb(51, 51, 51)",
                      boxShadow: "rgba(51, 51, 51, 0.49) 1px 1px 15px",
                      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                        boxShadow: "inherit",
                      },
                      "&:before": {
                        display: "none",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      display: "none",
                    },
                  }}
                />
              </Box>
            </Accordion>
          }
        </div>
      </Box>
    </div>
  );

  return <nav>{drawer}</nav>;
};

export default FilterPanel;
