import { Grid, useTheme, useMediaQuery } from "@mui/material";
import React from "react";

import LeftSection, { LeftSectionProps } from "./ProfileLeftSection";

interface LeftMenuProps extends LeftSectionProps {
  isMenuShow?: Boolean;
}

const LeftMenuBar = (props: LeftMenuProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return !isMobile || (props.isMenuShow && isMobile) ? (
    <>
      <Grid
        p={2}
        xs={12}
        lg={3}
        md={3}
        sx={{
          padding: { xs: "0%", sm: "0%" },
        }}
        item
      >
        <LeftSection
          {...props}
          user={{ firstName: "FirstName", lastName: "LastName" }}
        />
      </Grid>
      <Grid item maxWidth={"5.692308% !important"} xs={1}></Grid>
    </>
  ) : null;
};

export default LeftMenuBar;
