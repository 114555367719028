const imagepath = {
  login: require("../assets/images/login.png"),
  signup: require("../assets/images/signup.png"),
  forgotPassword: require("../assets/images/forgotPassword.png"),
  logo: require("../assets/images/logo.png"),
  dress: require("../assets/images/dress.png"),
  dress1: require("../assets/images/cardImg1.png"),
  visa: require("../assets/images/visa.png"),
  mada: require("../assets/images/mada.png"),
  masterCard: require("../assets/images/masterCard.png"),
  applePay: require("../assets/images/applePay.png"),
  cross: require("../assets/images/cross.png"),
  arrow: require("../assets/images/arrow.png"),
  zoom: require("../assets/images/zoom.png"),
  delivery: require("../assets/images/delivery.png"),
  background: require("../assets/images/background.png"),
  slider1: require("../assets/images/slider1.png"),
  slider2: require("../assets/images/slider2.png"),
  slider3: require("../assets/images/slider3.png"),
  slider4: require("../assets/images/slider4.png"),
  location: require("../assets/images/location.png"),
  // profile: require("../assets/images/profile.png"),
  calling: require("../assets/images/calling.png"),
  remove: require("../assets/images/remove.png"),
  headerLogo: require("../assets/images/headerLogo.png"),
  order: require("../assets/images/order.png"),
  product_215_2: require("../assets/images/product_215_1.jpg"),
  product_215_1: require("../assets/images/product_215_1.jpg"),
  product_215_3: require("../assets/images/product_215_3.jpg"),
  product_216_1: require("../assets/images/product_219_1.jpg"),
  product_217_4: require("../assets/images/product_217_4.jpg"),
  product_216_3: require("../assets/images/product_216_3.jpg"),
  product_217_2: require("../assets/images/product_217_2.jpg"),
  product_217_3: require("../assets/images/product_217_3(2).jpg"),
  product_223_1: require("../assets/images/product_223_1.jpg"),
  product_503_4: require("../assets/images/product_503_3.jpg"),
  product_503_3: require("../assets/images/product_503_4.jpg"),
  product_503_5: require("../assets/images/product_503_5.jpg"),
  thumb1: require("../assets/images/thumb1.png"),
  thumb2: require("../assets/images/thumb2.png"),
  thumb3: require("../assets/images/thumb3.png"),
  thumb4: require("../assets/images/thumb4.png"),
  details1: require("../assets/images/details1.png"),
  dress3: require("../assets/images/dress3.png"),
  dress4: require("../assets/images/dress4.png"),
  down: require("../assets/images/down.png"),
};
export default imagepath;
