import { Container, Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useTranslation } from "react-i18next";

const JoinUsSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleBackToHome = () => {
    navigate(CurrentLanguagePath(""));
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        textAlign: "center",
        marginTop: "70px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {t("joinUs.title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("joinUs.message")}
      </Typography>
      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={10}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleBackToHome}
            style={{
              height: "56px",
              borderRadius: "0px",
              textTransform: "capitalize",
            }}
          >
            {t("joinUs.buttonText")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JoinUsSuccess;
