import React from "react";
import { styled } from "@mui/material/styles";
import FormLabel from "@mui/material/FormLabel";

const CustomLabel = styled(FormLabel)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "17.34px",
  color: "#131118",
  marginTop: 10,
});

export default CustomLabel;
