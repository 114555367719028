import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  KeyboardArrowRight,
  PlaceOutlined,
  PersonOutlineOutlined,
  CallOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import COLORS from "../constant/colors";
import imagepath from "../constant/imagepath";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import useAuthStore from "../zustand/store/user";
import { CurrentLanguagePath } from "../utils/helper";

interface AccountSection {
  id: number;
  title: string;
  icon: React.ReactElement;
  route?: string;
}

export interface LeftSectionProps {
  selectedItem: number;
  handleItemClick?: (sectionId: number) => void;
  user?: {
    firstName: string;
    lastName: string;
  };
  accountSections?: AccountSection[];
}

const menu: AccountSection[] = [
  {
    id: 0,
    title: t("account.overview"),
    icon: <KeyboardArrowRight />,
    route: "account",
  },
  {
    id: 1,
    title: t("account.view_all_orders"),
    icon: <KeyboardArrowRight />,
    route: "account/orders",
  },
  {
    id: 2,
    title: t("account.my_addresses"),
    icon: <PlaceOutlined />,
    route: "account/address",
  },
  {
    id: 3,
    title: t("account.my_information"),
    icon: <PersonOutlineOutlined />,
    route: "account/profile",
  },
  {
    id: 4,
    title: t("account.contact_us"),
    icon: <CallOutlined className="rtl-transform" />,
    route: "contact_us",
  },
];

const LeftSection: React.FC<LeftSectionProps> = ({
  selectedItem,
  handleItemClick,
  user,
  accountSections = menu,
}) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleNavigation = (section: AccountSection) => {
    handleItemClick && handleItemClick(section.id);
    if (section.route) {
      navigate(CurrentLanguagePath(section.route));
    }
  };

  return (
    <Box sx={{ padding: { xs: "10px", sm: "0px" } }}>
      <Box sx={{ position: "relative", borderRadius: "2px" }}>
        <img
          src={imagepath.background}
          alt="Background"
          width={"100%"}
          height={"80px"}
        />
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            left: direction === "rtl" ? "unset" : "20px",
            right: direction === "rtl" ? "20px" : "unset",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", lineHeight: "26.01px" }}
            color={COLORS.WHITE}
          >
            WELCOME😳
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "23.12px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "220px",
            }}
            color={COLORS.WHITE}
          >
            {useAuthStore.getState().userInfo?.first_name}{" "}
            {useAuthStore.getState().userInfo?.last_name}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        {!isMobile &&
          accountSections.slice(0, 1).map((section) => (
            <Box
              key={section.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 15px",
                cursor: "pointer",
                mb: 1,
                borderRadius: "2px",
                backgroundColor:
                  selectedItem === section.id ? COLORS.BTN_BLACK : COLORS.WHITE,
                color:
                  selectedItem === section.id ? COLORS.WHITE : COLORS.BLACK,
                "&:hover": {
                  backgroundColor: COLORS.BTN_BLACK,
                  color: COLORS.WHITE,
                  "& .MuiTypography-root": {
                    color: COLORS.WHITE,
                  },
                },
              }}
              onClick={() => handleNavigation(section)}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "23.12px",
                }}
                color="inherit"
              >
                {section.title}
              </Typography>
              <KeyboardArrowRight className="rtl-transform" />
            </Box>
          ))}
        {accountSections.slice(1, 2).map((section) => (
          <Box
            key={section.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 15px",
              cursor: "pointer",
              mb: 1,
              borderRadius: "2px",
              backgroundColor:
                selectedItem === section.id ? COLORS.BTN_BLACK : COLORS.WHITE,
              color: selectedItem === section.id ? COLORS.WHITE : COLORS.BLACK,
              "&:hover": {
                backgroundColor: COLORS.BTN_BLACK,
                color: COLORS.WHITE,
                "& .MuiTypography-root": {
                  color: COLORS.WHITE,
                },
              },
            }}
            onClick={() => handleNavigation(section)}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "23.12px",
              }}
              color="inherit"
            >
              {section.title}
            </Typography>
            <KeyboardArrowRight className="rtl-transform" />
          </Box>
        ))}
      </Box>
      <Box mt={2}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "26.01px",
            mb: 1,
          }}
        >
          MY ACCOUNT
        </Typography>
        {accountSections.slice(2).map((section) => (
          <Box
            key={section.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 15px",
              cursor: "pointer",
              mb: 1,
              borderRadius: "2px",
              backgroundColor:
                selectedItem === section.id ? COLORS.BTN_BLACK : COLORS.WHITE,
              color: selectedItem === section.id ? COLORS.WHITE : COLORS.BLACK,
              "&:hover": {
                backgroundColor: COLORS.BTN_BLACK,
                color: COLORS.WHITE,
                "& .MuiTypography-root": {
                  color: COLORS.WHITE,
                },
              },
            }}
            onClick={() => handleNavigation(section)}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "23.12px",
              }}
              color="inherit"
            >
              {section.icon}&nbsp; {section.title}
            </Typography>
            <KeyboardArrowRight className="rtl-transform" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LeftSection;
