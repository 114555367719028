import React, { useEffect } from "react";
import "./App.css";
import { Box, useTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import AppRoutes from "./routes";
import {} from "@emotion/react";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import { themeLTR, themeRTL } from "./utils/theme/theme";
import { ToastContainer } from "react-toastify";
import useAuthStore from "./zustand/store/user";
import Loader from "./components/Loader";
import { useLanguageStore } from "./zustand/store/language";
import { supportedLanguages } from "./constant/baseUrl";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { init, isLoading }: any = useAuthStore();
  const setLanguage = useLanguageStore((state) => state.setLanguage);
  const language = useLanguageStore((state) => state.language);
  const [isTabVisible, setIsTabVisible] = React.useState(true);
  const { pathname } = window.location;
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const pathSegments = pathname.split("/").filter(Boolean);
        const currentLang = pathSegments[0];
        const languageFromUrl = supportedLanguages.includes(currentLang)
          ? currentLang
          : null;
        const storedLanguage = localStorage.getItem("i18nextLng") || "en";
        if (languageFromUrl !== storedLanguage) {
          const url = new URL(window.location.href);
          const pathSegments = url.pathname.split("/");
          if (pathSegments.length > 1) {
            pathSegments[1] = storedLanguage;
          }

          url.pathname = pathSegments.join("/");
          window.history.pushState({}, "", url.toString());
        }

        setIsTabVisible(true);
      } else {
        setIsTabVisible(false);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [pathname]);

  useEffect(() => {
    useAuthStore.setState({ isMobile: isMobile });
  }, [isMobile]);

  function updateLanguage(languageToUse: string) {
    i18n.changeLanguage(languageToUse);
    setLanguage(languageToUse);
    document.documentElement.dir = languageToUse === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", languageToUse === "ar" ? "rtl" : "ltr");
  }

  useEffect(() => {
    init();
    const pathSegments = pathname.split("/").filter(Boolean);
    const currentLang = pathSegments[0];
    const languageFromUrl = supportedLanguages.includes(currentLang)
      ? currentLang
      : null;
    const storedLanguage = localStorage.getItem("i18nextLng");
    const languageToUse = languageFromUrl || storedLanguage || "en";
    updateLanguage(languageToUse);
  }, [i18n.language, setLanguage, pathname]);

  return (
    <ThemeProvider theme={language === "en" ? themeLTR : themeRTL}>
      <I18nextProvider i18n={i18n}>
        <Box id={"mainWrapper"}>
          {isLoading && <Loader />}
          {/* <AppRoutes /> */}
          <RouterProvider router={AppRoutes()} />
          <ToastContainer />
        </Box>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
