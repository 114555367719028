import { styled } from "@mui/system";
import { Badge, BadgeProps, Box, Button, Typography } from "@mui/material";
import COLORS from "../../../constant/colors";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",

  "&.MuiTypography-h4": {
    fontSize: "32px",
  },
  "&.MuiTypography-h6": {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "34.68px",
  },
  "&.MuiTypography-body2": {
    fontSize: "12px",
    lineHeight: "17.34px",
    fontWeight: "400",
  },
  "&.MuiTypography-body1": {
    fontSize: "16px",
    lineHeight: "23.12px",
    fontWeight: 400,
  },
  "&.MuiTypography-caption": {
    // New style for 14px font size
    fontSize: "14px",
    lineHeight: "23.12px",
    fontWeight: 400,
  },
}));

const FlexBox = styled(Box)<{ direction?: "row" | "column" }>(
  ({ direction }) => ({
    display: "flex",
    flexDirection: direction || "row",
    alignItems: direction === "column" ? "flex-start" : "center",
    marginBottom: "16px",
  })
);

const ColumnBox = styled(Box)(({ theme }) => ({
  width: "131px",
  height: "48px",
  backgroundColor: "#A4A1AA33",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "8px",
}));

const CustomButton = styled(Button)(({ theme, variant }) => ({
  maxWidth: "220px",
  borderRadius: 0,
  color: theme.palette.common.white,
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 500,
  backgroundColor:
    variant === "contained" ? COLORS.PRIMARY_BUTTON : COLORS.PRIMARY_BUTTON,
  "&:hover": {
    backgroundColor:
      variant === "contained" ? COLORS.PRIMARY_BUTTON : COLORS.PRIMARY_BUTTON,
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme, dir }) => ({
  "& .MuiBadge-badge": {
    right: dir === "rtl" ? "23px" : "6px",
    top: "23px",
    padding: "0px 0px",
    color: "#000",
    background: "#da8344 !important",
    minWidth: "12px",
    height: "12px",
    fontSize: "10px",
  },
}));

export { StyledTypography, FlexBox, ColumnBox, CustomButton, StyledBadge };
