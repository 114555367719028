import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)({
  backgroundColor: "#131118",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "23.12px",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#232229",
  },
});

export default CustomButton;
