import { styled } from "@mui/system";
import {
  TextField,
  FormControl,
  FormLabel as muiFormLabel,
  Box,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// Define the styled components
export const StyledFormControl = styled(FormControl)({
  // padding: 16,
  backgroundColor: "#fff",
});

export const StyledTextField = styled(TextField)({
  ".MuiOutlinedInput-input": {
    fontSize: 15,
    fontWeight: 400,
    textAlign: "left",
    color: "#131118",
    padding: "8px",
    borderRadius: "2px",
    border: "0px",
  },
  ".MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #131118",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #131118",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #131118",
    },
  },
  ".css-1wc848c-MuiFormHelperText-root": {
    color: "#660331",

    fontSize: 12,
    marginTop: 0,
    marginLeft: 0,
  },
});

export const FormLabel = styled(muiFormLabel)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "17.34px",
  textAlign: "left",
  color: "#131118",
});

export const StyledDot = styled("button")({
  fontSize: "30px",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  background: "#ccc",
  border: "none",
  outline: "none",
  transition: "all 0.3s ease",
  "&:before": {
    content: '""',
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: "#fff",
    transition: "all 0.3s ease",
  },
  "&.slick-active": {
    "&:before": {
      background: "red",
    },
  },
});

export const FooterHeading = styled(Typography)({
  fontWeight: 300,
  fontSize: "18px",
  lineHeight: "30px",
  textAlign: "right",
  color: "#FFFFFF",
  marginBottom: "10px",
});

export const FooterLink = styled(Link)(({ theme }) => ({
  fontWeight: 300,
  lineHeight: "34.68px",
  textAlign: "right",
  textDecoration: "underline",
  color: "#fff",
  fontSize: "18px",
}));

export const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));
