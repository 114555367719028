import axiosInstance from "../apiClient";
import API from "../endpoints";

export const homeService = {
  getCategories: () => {
    return axiosInstance.get(API.CATEGORY_MENU);
  },
  getHomeData: () => {
    return axiosInstance.post(API.HOME);
  },
  getFaq: () => {
    return axiosInstance.post(API.FAQ);
  },
  getPages: (data: any) => {
    return axiosInstance.post(API.PAGES, data);
  },
  getSetting: () => {
    return axiosInstance.get(API.SETTING);
  },
};
