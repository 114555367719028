import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  AppBar,
  Toolbar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomButton from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CurrentLanguagePath } from "../../utils/helper";
import { checkoutServices } from "../../api/services/checkout";
import ShowToast from "../../utils/ShowToast";
import { ConfirnOrderTypes } from "../../types/order";
import useAuthStore from "../../zustand/store/user";
// import queryString from "query-string";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const parsed = queryString.parse(location.search);
  // const checkout_id = parsed.resourcePath;
  const params = new URLSearchParams(location.search);
  const checkout_id = params.get("id");
  const { t, i18n } = useTranslation();
  const [orderDetails, setOrderDetails] = useState<ConfirnOrderTypes | null>(
    null
  );
  // const { checkoutId: checkout_id } = useParams();
  const { setUserInfo } = useAuthStore();
  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const marginProp = isRTL ? "borderLeft" : "borderRight";
  const placeOrder = async () => {
    try {
      const response = await checkoutServices.verifyPayment({
        checkout_id,
      });
      if (response && response.status === 200) {
        setOrderDetails(response.data);
        if (
          response.data?.login_details &&
          response.data?.login_details !== null
        ) {
          setUserInfo(response.data?.login_details);
        }
      }
    } catch (err: any) {
      if (err && err.status === 400) {
        ShowToast(err.message, "error");
        setTimeout(() => {
          // navigate(-1);
          window.location.href = CurrentLanguagePath('checkout')
        }, 2000);
      }
    } finally {
    }
  };

  useEffect(() => {
    if (checkout_id) {
      placeOrder();
    }
  }, [checkout_id]);

  if (!orderDetails) {
    return <></>;
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber || phoneNumber.length <= 2) {
      return phoneNumber;
    }

    const startDigits = phoneNumber.substring(0, 2);
    const asterisks = "*".repeat(phoneNumber.length - 2);
    return `${startDigits}${asterisks}`.toString();
  };

  let number = formatPhoneNumber(orderDetails?.phone_number);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#fff",
          color: "#000",
        }}
        elevation={1}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              direction: "ltr",
              cursor: "pointer",
            }}
            onClick={() => navigate(CurrentLanguagePath(""))}
          >
            <img
              src={require("../../assets/images/headerLogo.png")}
              alt="lusive"
              style={{ height: 38 }}
            />
            <img
              src={require("../../assets/images/lusive.png")}
              alt="lusive"
              style={{ height: 23 }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth={"sm"}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={100}
          height={100}
          borderRadius="50%"
          bgcolor="rgba(164, 161, 170, 0.2)"
          margin="auto"
          mt={1}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={80}
            height={80}
            borderRadius="50%"
            bgcolor="rgba(164, 161, 170, 0.6)"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={60}
              height={60}
              borderRadius="50%"
              bgcolor="#660331"
            >
              <IconButton disableRipple style={{ background: "none" }}>
                <CheckCircleIcon style={{ fontSize: 50, color: "#FFFFFF" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Typography fontSize={"15px"} fontWeight={"900"} textAlign={"center"}>
          {t("orderConfirmation.order_received")} <br />
          {t("orderConfirmation.successfully")} !🎉
        </Typography>
        <Typography fontSize={"14px"} mt={1} mb={2} flexWrap={"wrap"}>
          {t("orderConfirmation.description")}
        </Typography>
        <TableContainer component={Paper} sx={{ borderRadius: "0px" }}>
          <Table style={{ border: "2px solid black" }}>
            <TableBody sx={{ direction: dir }}>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {t("orderConfirmation.order_number")}
                </TableCell>
                <TableCell sx={{ textAlign: isRTL ? "right" : "left" }}>
                  #{orderDetails.order_id}
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {t("orderConfirmation.products")}
                </TableCell>
                <TableCell sx={{ textAlign: isRTL ? "right" : "left" }}>
                  {orderDetails?.items?.split(";").map((data) => (
                    <Typography variant="body2">{`${data?.trim()}`}</Typography>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("orderConfirmation.shipping_address")}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {orderDetails.shipping_address}
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {t("orderConfirmation.recipient")}
                </TableCell>
                <TableCell
                  sx={{ textAlign: isRTL ? "right" : "left", display: "flex" }}
                >
                  <Typography variant="body2">
                    {`${orderDetails.recipient}`} &nbsp;
                  </Typography>
                  &nbsp; <span>{number}</span>
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {t("orderConfirmation.delivery_method")}
                </TableCell>
                <TableCell sx={{ textAlign: isRTL ? "right" : "left" }}>
                  {orderDetails.delivery_method}
                </TableCell>
              </TableRow>
              <TableRow style={{ border: "2px solid black" }}>
                <TableCell
                  component="th"
                  sx={{
                    [marginProp]: "2px solid black",
                    fontWeight: "500",
                    textAlign: isRTL ? "right" : "left",
                  }}
                >
                  {t("orderConfirmation.payment_method")}
                </TableCell>
                <TableCell sx={{ textAlign: isRTL ? "right" : "left" }}>
                  {orderDetails.payment_method} &nbsp;
                  <span
                    style={{ fontWeight: "500" }}
                  >{`${orderDetails.grand_total?.toString()} SAR`}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <CustomButton
            variant="outlined"
            sx={{
              width: { xs: "288px", sm: "392px" },
              height: { xs: "41px", sm: "56px" },
              padding: "20px",
              borderRadius: "0px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate(CurrentLanguagePath(""))}
          >
            {t("orderConfirmation.back_to_home")} &nbsp;{" "}
            {dir === "rtl" ? "←" : "→"}
          </CustomButton>
        </Box>
      </Container>
    </>
  );
};

export default OrderConfirmation;
