import axiosInstance from "../apiClient";
import API from "../endpoints";

export const userService = {
  getUser: () => {
    return axiosInstance.get(API.PROFILE);
  },
  signUp: (data: any) => {
    return axiosInstance.post(API.SIGNUP, data);
  },
  signIn: (data: any) => {
    return axiosInstance.post(API.SIGNIN, data);
  },
  phoneLogin: (data: any) => {
    return axiosInstance.post(API.PHONE_LOGIN, data);
  },
  verifyPhone: (data: any) => {
    return axiosInstance.post(API.VERIFY_PHONE, data);
  },
  forgotPassword: (data: any) => {
    return axiosInstance.post(API.FORGOT_PASSWORD, data);
  },
  resetPassword: (data: any) => {
    return axiosInstance.post(API.RESET_PASSWORD, data);
  },
  logout: () => {
    return axiosInstance.post(API.LOGOUT);
  },
  getUserProfile: () => {
    return axiosInstance.get(API.PROFILE);
  },
  updateUserProfile: (data: any) => {
    return axiosInstance.post(API.UPDATEPROFILE, data);
  },
  changeEmail: (data: any) => {
    return axiosInstance.post(API.CHANGEEMAIL, data);
  },
  changePassword: (data: any) => {
    return axiosInstance.post(API.CHANGEPASSWORD, data);
  },
};
