import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as OrderImg } from "../../../assets/svgIcons/oder.svg";
import COLORS from "../../../constant/colors";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LeftMenuBar from "../../../components/LeftMenuBar";
import { useTranslation } from "react-i18next";
import { CurrentLanguagePath } from "../../../utils/helper";
import { Helmet } from "react-helmet";
import { orderService } from "../../../api/services/orderService";
import { OrderListResponse } from "../../../types/order";
import moment from "moment";

const OrderList = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [orderList, setOrdereList] = useState<OrderListResponse>(
    {} as OrderListResponse
  );
  const getOrdersList = async () => {
    try {
      const response = await orderService.getOrders();
      if (response && response.status === 200) {
        setOrdereList(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrdersList();
  }, []);

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "delivered":
        return "#28C76F";
      case "pending":
        return "#FF9F43";
      case "processed":
        return "#7367F0";
      case "on_delivery":
        return "#A8AAA3";
      case "approved":
        return "#00CFE8";
      default:
        return "red";
    }
  };

  return (
    <Box sx={{ background: "rgb(164 161 170 / 20%)" }}>
      <Helmet>
        <title>Orders</title>
        <meta
          name="description"
          content="View your order history and track your current orders."
        />
        <meta
          name="keywords"
          content="orders, order history, order tracking, e-commerce"
        />
        <link rel="canonical" href="http://mysite.com/account/orders" />
      </Helmet>

      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={1} />

          <Grid item xs={12} lg={8} md={8} gap={0}>
            {isSmall && (
              <Box mb={2} display={"flex"} alignItems={"center"}>
                <IconButton
                  onClick={() => navigate(CurrentLanguagePath("account"))}
                  sx={{
                    [direction === "rtl" ? "marginRight" : "marginLeft"]:
                      "-10px",
                    padding: "0px !important",
                    fontSize: "20px",
                    width: "25px",
                  }}
                >
                  <ChevronLeft className="rtl-transform" />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                  }}
                >
                  {t("order.overview")}
                </Typography>
              </Box>
            )}
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "26.01px",
                marginX: "5px",
                textTransform: "uppercase",
              }}
              gutterBottom
            >
              {t("order.active_orders")}
            </Typography>
            {orderList &&
              orderList?.active_orders?.map((order, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    backgroundColor: COLORS.WHITE,
                    padding: "10px",
                    borderRadius: "4px",
                    marginBottom: "15px",
                    position: "relative",
                    overflow: { xs: "auto", sm: "unset" },
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: direction === "rtl" ? "" : "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          color: "#b4b4b4",
                        }}
                      >
                        {t("order.order_date")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26.01px",
                        }}
                      >
                        {moment(new Date(order?.order_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          color: "#b4b4b4",
                        }}
                      >
                        {t("order.price")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26.01px",
                        }}
                      >
                        {order.grandtotal} SAR
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          color: "#b4b4b4",
                        }}
                      >
                        {t("order.order")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26.01px",
                        }}
                      >
                        #{order.order_id}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: direction === "rtl" ? "30px" : "unset",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          color: "#b4b4b4",
                        }}
                      >
                        {t("order.status")}
                      </Typography>
                      <Typography
                        color={COLORS.BLACK}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26.01px",
                          color: getStatusColor(order.status),
                          textTransform: "capitalize",
                        }}
                      >
                        {order.status === "on_delivery"
                          ? "On Delivery"
                          : order.status}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(
                        CurrentLanguagePath(
                          `account/orders/${order.order_id}/order_details`
                        )
                      )
                    }
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                    }}
                  >
                    <ChevronRight className="rtl-transform" />
                  </IconButton>
                </Box>
              ))}
            {orderList?.active_orders?.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginBottom={4}
              >
                <OrderImg style={{ backgroundColor: COLORS.WHITE }} />
                <Button
                  onClick={() => navigate("/")}
                  variant="contained"
                  sx={{
                    marginTop: 2,
                    width: "297px",
                    borderRadius: "0px",
                    backgroundColor: COLORS.BTN_BLACK,
                  }}
                >
                  {t("order.shop_now")}
                </Button>
              </Box>
            )}

            {orderList?.past_orders?.length > 0 && (
              <>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "26.01px",
                    marginTop: "73px",
                  }}
                  gutterBottom
                >
                  {t("order.past_orders")}
                </Typography>
                {orderList &&
                  orderList?.past_orders?.map((order, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        backgroundColor: COLORS.WHITE,
                        padding: "10px",
                        borderRadius: "4px",
                        marginBottom: "15px",
                        position: "relative",
                        overflow: { xs: "auto", sm: "unset" },
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: direction === "rtl" ? "" : "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              lineHeight: "23.12px",
                              color: "#b4b4b4",
                            }}
                          >
                            {t("order.order_date")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "26.01px",
                            }}
                          >
                            {moment(new Date(order?.order_date)).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              lineHeight: "23.12px",
                              color: "#b4b4b4",
                            }}
                          >
                            {t("order.price")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "26.01px",
                            }}
                          >
                            {order.grandtotal}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              lineHeight: "23.12px",
                              color: "#b4b4b4",
                            }}
                          >
                            {t("order.order")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "26.01px",
                            }}
                          >
                            {order.order_id}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: direction === "rtl" ? "30px" : "unset",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "300",
                              lineHeight: "23.12px",
                              color: "#b4b4b4",
                            }}
                          >
                            {t("order.status")}
                          </Typography>
                          <Typography
                            color={COLORS.BLACK}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "26.01px",
                              color: getStatusColor(order?.status),
                              textTransform: "capitalize",
                            }}
                          >
                            {order.status}
                          </Typography>
                        </Box>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() =>
                          navigate(
                            CurrentLanguagePath(
                              `account/orders/${order.order_id}/order_details`
                            )
                          )
                        }
                        sx={{
                          display: "flex",
                          alignSelf: "center",
                        }}
                      >
                        <ChevronRight className="rtl-transform" />
                      </IconButton>
                    </Box>
                  ))}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OrderList;
