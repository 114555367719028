const COLORS = {
  SNOW_DRIFT: "#F8F9FA",
  PINK_SWAN: "#B9B7BE",
  DAWN_PINK: "#EDECEE",
  PRIMARY_BUTTON: "#131118",
  BLACK_ROSE: "#660331",
  ERROR: "#660331",
  BLACK: "#000000",
  DEEP_SEA: "#018849",
  WHITE: "#FFFFFF",
  WHITE_SMOKE: "#F5F5F5",
  COPPER_ORANGE: "#DA8344",
  BTN_BLACK: "#131118",
};

export default COLORS;
