import React, { useState } from "react";
import { Dialog, DialogContent, Box, Button, IconButton } from "@mui/material";
import { Close, ContentCopy } from "@mui/icons-material";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  // TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

type SharePopUpProps = {
  open: boolean;
  onClose: () => void;
  productUrl: string;
  productName: string;
};

const SharePopUp: React.FC<SharePopUpProps> = ({
  open,
  onClose,
  productUrl,
  productName,
}) => {
  const [copySuccess, setCopySuccess] = useState<string>("");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(productUrl).then(
      () => {
        setCopySuccess("Link copied to clipboard!");
        setTimeout(() => setCopySuccess(""), 3000);
      },
      (err) => {
        setCopySuccess("Failed to copy link.");
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <IconButton onClick={onClose} sx={{ height: '30px', width: "30px", borderRadius: "15px", alignSelf: "flex-end" }}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <h2>Share this product</h2>
          <Box display="flex" gap={2}>
            <FacebookShareButton url={productUrl} title={productName}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={productUrl} title={productName}>
              <img
                src={require("../assets/images/twitter.png")}
                alt="twitter"
                style={{
                  width: "32px",
                  height: "32px",
                  color: "#000",
                  filter: "brightness(0) invert(0)",
                }}
              />
            </TwitterShareButton>
            <LinkedinShareButton url={productUrl} title={productName}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={productUrl} title={productName}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Box>
          <Button
            startIcon={<ContentCopy />}
            onClick={handleCopyLink}
            variant="outlined"
            sx={{
              mt: 2,
              "& .MuiButton-startIcon": {
                mx: "4px !important",
              },
            }}
          >
            Copy Link
          </Button>
          {copySuccess && (
            <Box mt={1} color="green">
              {copySuccess}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SharePopUp;
