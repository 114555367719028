import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { textFieldStyles } from "../../checkout/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

interface FormValues {
  brandName: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  password: string;
  link: string;
  terms: boolean;
  brandLogo: File | null;
}

// Define the validation schema using Yup
const validationSchema = Yup.object({
  brandName: Yup.string().required("Brand Name is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobile: Yup.string()
    .matches(/^5\d{8}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
      "Invalid email address"
    ),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  link: Yup.string().url("Invalid URL"),
  terms: Yup.bool().oneOf([true], "You must agree to the Terms & Conditions"),
  brandLogo: Yup.mixed().required("Brand Logo is required"),
});

const JoinUs: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues: FormValues = {
    brandName: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password: "",
    link: "",
    terms: false,
    brandLogo: null,
  };

  const handleSubmit = (values: FormValues) => {
    console.log(values);
    navigate(CurrentLanguagePath("join-us-success"));
  };

  const direction = i18next.dir();

  return (
    <Container maxWidth="sm" sx={{ minHeight: "100vh", pt: 4, pb: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("joinUs.heading")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("joinUs.subHeading")}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.brandName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="brandName"
                  placeholder={t("joinUs.brandNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.brandName}
                  error={touched.brandName && !!errors.brandName}
                  helperText={<ErrorMessage name="brandName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.brandName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.firstName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="firstName"
                  placeholder={t("joinUs.firstNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={touched.firstName && !!errors.firstName}
                  helperText={<ErrorMessage name="firstName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.firstName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.lastName")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="lastName"
                  placeholder={t("joinUs.lastNamePlaceholder")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={touched.lastName && !!errors.lastName}
                  helperText={<ErrorMessage name="lastName" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.lastName")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.mobile")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="mobile"
                  placeholder={t("joinUs.mobile")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobile}
                  error={touched.mobile && !!errors.mobile}
                  helperText={<ErrorMessage name="mobile" />}
                  sx={textFieldStyles(direction)}
                  inputProps={{
                    maxLength: 9,
                    inputMode: "numeric",
                  }}
                  title={t("joinUs.mobile")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.email")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="email"
                  placeholder={t("joinUs.email")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && !!errors.email}
                  helperText={<ErrorMessage name="email" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.password")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  label=""
                  name="password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && !!errors.password}
                  helperText={<ErrorMessage name="password" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.password")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.instagramOrTiktokLink")}
                </Typography>
                <Field
                  as={TextField}
                  fullWidth
                  // label={t('joinUs.instagramOrTiktokLink')}
                  label=""
                  name="link"
                  placeholder={t("joinUs.instagramOrTiktokLink")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.link}
                  error={touched.link && !!errors.link}
                  helperText={<ErrorMessage name="link" />}
                  sx={textFieldStyles(direction)}
                  title={t("joinUs.instagramOrTiktokLink")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("joinUs.brandLogo")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <input
                    type="file"
                    onChange={(event) =>
                      setFieldValue(
                        "brandLogo",
                        event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null
                      )
                    }
                    title={t("joinUs.brandLogo")}
                  />
                </div>
                <ErrorMessage
                  name="brandLogo"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Field as={Checkbox} name="terms" color="default" />}
                  label={
                    <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                      {t("i_agree")}{" "}
                      <span style={{ fontSize: "16px", fontWeight: "700" }}>
                        <a
                          href={CurrentLanguagePath("terms-and-conditions")}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#000", textDecoration: "underline" }}
                        >
                          {t("terms_and_conditions")}
                        </a>
                      </span>
                    </Typography>
                  }
                />
                <ErrorMessage name="terms" component="div" className="error" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{
                    borderRadius: "0px",
                    height: "56px",
                    textTransform: "capitalize",
                  }}
                >
                  {t("joinUs.continueToRegister")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default JoinUs;
