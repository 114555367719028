import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthStore from "../zustand/store/user";

type ToastType = "success" | "error" | "info" | "warning";
let activeToasts = new Set();

const ShowToast = (
  message: string,
  type: ToastType = "success",
  position: ToastOptions["position"] = "top-right"
): void => {
  const options: ToastOptions = {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: message,
    onClose: () => {
      activeToasts.delete(message);
    },
  };

  switch (type) {
    case "success":
      if (message) {
        toast.success(message, options);
      }

      break;
    case "error":
      if (!activeToasts.has(message)) {
        activeToasts.add(message);
        if (useAuthStore.getState().isMobile) {
          if (message) {
            toast.error(message, {
              ...options,
              position: "top-left",
            });
          }
        } else {
          if (message) {
            toast.error(message, {
              ...options,
            });
          }
        }
      }
      break;
    case "info":
      toast.info(message, options);
      break;
    case "warning":
      toast.warn(message, options);
      break;
    default:
      toast(message, options);
  }
};

export default ShowToast;
