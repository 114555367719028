import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import COLORS from "../../../constant/colors";
import { ArrowRightAlt, ChevronLeft } from "@mui/icons-material";
import LeftMenuBar from "../../../components/LeftMenuBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { checkoutServices } from "../../../api/services/checkout";
import ShowToast from "../../../utils/ShowToast";
import {
  OrderDetails as OrderDetailsTypes,
  Refund,
  RefundData,
  RefundReasons,
} from "../../../types/checkout";
import moment from "moment";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";
import ProductDialog from "../../../components/ProductDialog";
import { orderService } from "../../../api/services/orderService";
import RefundItemDialog from "../../../components/RefundItemDialog";

const steps = [
  "Pending",
  "Approved",
  "Processed",
  "Out for delivery",
  "Delivered",
];

interface StatusStepperProps {
  activeStep: number;
  direction: "ltr" | "rtl";
}

function OrderDetails() {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetailsTypes | null>(
    null
  );
  const [refundItems, setRefundItems] = useState<RefundData>({} as RefundData);
  const { orderId: order_id } = useParams();
  const { language } = useLanguageStore();
  const [returnReasons, setReturnReasons] = useState<RefundReasons[]>([]);

  const fetchorderDetails = useCallback(async () => {
    try {
      const response = await checkoutServices.getOrderDetails({
        order_id,
      });
      if (response && response.status === 200) {
        setOrderDetails(response.data);
      }
    } catch (err: any) {
      ShowToast(err.message, "error");
    }
  }, [order_id]);

  useEffect(() => {
    if (order_id) {
      fetchorderDetails();
    }
  }, [order_id, fetchorderDetails, language]);

  const RTLConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      left: "calc(+50% + 20px)",
      right: "calc(-50% + 20px)",
    },
  }));

  // const CustomStepIcon = styled("div")<{
  //   ownerState: { active: boolean; completed: boolean };
  // }>(({ theme, ownerState }) => ({
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   width: 24,
  //   height: 24,
  //   borderRadius: "50%",
  //   backgroundColor: ownerState.active
  //     ? theme.palette.primary.main
  //     : ownerState.completed
  //     ? theme.palette.success.main
  //     : theme.palette.grey[400],
  //   color: theme.palette.common.white,
  //   fontWeight: "bold",
  //   fontSize: "0.875rem",
  // }));

  const StatusStepper: React.FC<StatusStepperProps> = ({
    activeStep,
    direction,
  }) => {
    return (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={direction === "rtl" ? <RTLConnector /> : undefined}
      >
        {steps.map((label, index) => (
          <Step
            key={index}
            sx={{
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <StepLabel
              sx={{
                ".css-k1lnzc-MuiStepLabel-label.MuiStepLabel-alternativeLabel":
                  {
                    marginTop: "8px",
                  },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  const statusToStepIndex: { [key: string]: number } = {
    pending: 0,
    approved: 1,
    processed: 2,
    on_delivery: 3,
    delivered: 5,
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber || phoneNumber.length <= 2) {
      return phoneNumber;
    }

    const startDigits = phoneNumber.substring(0, 2);
    const asterisks = "*".repeat(phoneNumber.length - 2);
    return `+966${startDigits}${asterisks}`.toString();
  };

  const fetchReturnReasons = async () => {
    try {
      const response = await orderService.getRefundReasons();
      if (response && response.status === 200) {
        setReturnReasons(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReturnReasons();
  }, [language]);

  const returnOrder = async (selectedItems: {
    order_items_ids: number[];
    reason_of_refund: string;
    description: string;
  }) => {
    const request = {
      order_id: order_id,
      order_items_ids: selectedItems.order_items_ids,
      reason_of_refund: selectedItems.reason_of_refund,
      description: selectedItems.description,
    };

    try {
      const response: any = await orderService.returnOrder(request);
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        fetchorderDetails();
      } else {
        console.log("Error returning order:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error");
      console.log("Error returning order", error);
    }
  };

  const fetchReturnItems = async (id: string) => {
    const request = {
      refund_id: id,
    };
    try {
      const response = await orderService.getReturedItems(request);
      if (response && response.status === 200) {
        setRefundItems(response.data);
        setRefundDialogOpen(true);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "delivered":
        return "#28C76F";
      case "pending":
        return "#FF9F43";
      case "processed":
        return "#7367F0";
      case "on_delivery":
        return "#A8AAA3";
      case "approved":
        return "#00CFE8";
      default:
        return "red";
    }
  };

  return (
    <Box sx={{ background: "rgb(164 161 170 / 20%)" }}>
      <Helmet>
        <title>Order Details</title>
        <meta
          name="description"
          content="Detailed information about your order."
        />
        <meta
          name="keywords"
          content="order details, order information, e-commerce"
        />
        <link
          rel="canonical"
          href="http://mysite.com/account/orders/:orderId/order_details"
        />
      </Helmet>
      <Container maxWidth={"xl"}>
        <Grid
          container
          pb={8}
          sx={{
            paddingLeft: { xs: "0%", sm: "0%" },
            paddingRight: { xs: "0%", sm: "0%" },
            marginLeft: { xs: "0%", sm: "0%" },
            marginRight: { xs: "0%", sm: "0%" },
            paddingTop: { xs: "0%", sm: "2%" },
          }}
          columns={12}
        >
          <LeftMenuBar selectedItem={1} />

          <Grid item xs={12} lg={8} md={8} gap={0}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <ChevronLeft
                onClick={() => navigate(-1)}
                sx={{
                  [direction === "rtl" ? "marginRight" : "marginLeft"]: "-10px",
                  padding: "0px !important",
                  fontSize: "20px",
                  width: "25px",
                }}
                className="rtl-transform"
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26.01px",
                }}
                alignItems="center"
              >
                {t("order.view_all_orders")}
              </Typography>
            </Box>

            <div
              style={{
                [direction === "rtl" ? "marginRight" : "marginLeft"]: "16px",
              }}
            >
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                {/* left section */}
                <Grid
                  item
                  xs={12}
                  md={8}
                  // sx={{ paddingLeft: { xs: "0px !important" } }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "26.01px",
                    }}
                    gutterBottom
                  >
                    {t("order.order_details")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: COLORS.WHITE,
                      padding: "10px",
                      borderRadius: "4px",
                      "@media (max-width: 576px)": {
                        overflow: "auto",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "300",
                            lineHeight: "23.12px",
                            color: "#b4b4b4",
                          }}
                        >
                          {t("order.order")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "26.01px",
                          }}
                        >
                          #{orderDetails?.order_id}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "300",
                            lineHeight: "23.12px",
                            color: "#b4b4b4",
                          }}
                        >
                          {t("order.order_date")}
                        </Typography>
                        {orderDetails?.order_date && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "26.01px",
                            }}
                          >
                            {moment(new Date(orderDetails?.order_date)).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: direction === "rtl" ? "30px" : "unset",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "300",
                            lineHeight: "23.12px",
                            color: "#b4b4b4",
                          }}
                        >
                          {t("order.item_ordered")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "26.01px",
                          }}
                        >
                          {orderDetails?.item_count}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        "@media (max-width: 320px)": {
                          [direction === "rtl" ? "marginRight" : "marginLeft"]:
                            "10px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          color: "#b4b4b4",
                        }}
                      >
                        {t("order.price")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26.01px",
                        }}
                      >
                        {orderDetails?.grandtotal} SAR
                      </Typography>
                    </Box>
                  </Box>
                  {orderDetails &&
                    orderDetails.items &&
                    orderDetails.items.map((details, index) => {
                      return (
                        <>
                          <Box mt={2} key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "6px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "26.01px",
                                  color: getStatusColor(details.status),
                                  textTransform: "capitalize",
                                }}
                              >
                                {details.status === "on_delivery"
                                  ? "On Delivery"
                                  : details.status === "delivered"
                                  ? `Delivered on ${moment(
                                      details.delivered_at
                                    ).format("DD/MM/YYYY")}`
                                  : details.status}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "26.01px",
                                }}
                              >
                                Track{" "}
                                <ArrowRightAlt className="rtl-transform" />
                              </Typography>
                            </Box>
                            <Box
                              bgcolor={COLORS.WHITE}
                              sx={{
                                padding: "10px 0px 3px 0px",
                                borderRadius: "4px",
                                marginBottom: "16px",
                              }}
                            >
                              <StatusStepper
                                activeStep={
                                  statusToStepIndex[
                                    details.status?.toLowerCase()
                                  ]
                                }
                                direction={direction}
                              />
                            </Box>
                          </Box>
                          {details &&
                            details.items.map((items, idx) => {
                              return (
                                <Paper
                                  key={idx}
                                  elevation={0}
                                  sx={{
                                    padding: 2,
                                    marginBottom: 2,
                                    "@media (max-width: 320px)": {
                                      padding:
                                        direction === "rtl" ? "16px" : "8px",
                                    },
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                      <Link
                                        to={CurrentLanguagePath(
                                          `product-details/${items?.slug}`
                                        )}
                                      >
                                        <Box
                                          component="img"
                                          src={items.product_image_url}
                                          alt="Product Image"
                                          sx={{
                                            maxWidth: "100px",
                                            minHeight: "100px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Link>
                                    </Grid>
                                    <Grid item xs={9}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginX: { xs: "30px", sm: "0px" },
                                        }}
                                      >
                                        <Typography
                                          fontSize={"18px"}
                                          lineHeight={"20px"}
                                          fontWeight={"400"}
                                          textTransform={"uppercase"}
                                        >
                                          {details.vendor_name}
                                        </Typography>
                                        {items.price === items.sale_price && (
                                          <Typography
                                            fontSize={"14px"}
                                            color="#000000"
                                            fontWeight={"500"}
                                            ml={1}
                                            sx={{
                                              marginRight:
                                                direction === "rtl"
                                                  ? "5px"
                                                  : "unset",
                                              "@media (max-width: 320px)": {
                                                fontSize: "12px",
                                              },
                                            }}
                                          >
                                            {items.price}
                                          </Typography>
                                        )}
                                        {items.price !== items.sale_price && (
                                          <Box display="flex">
                                            <Typography
                                              fontSize={"14px"}
                                              sx={{
                                                textDecoration: "line-through",
                                                "@media (max-width: 320px)": {
                                                  fontSize: "12px",
                                                },
                                              }}
                                              fontWeight={"500"}
                                            >
                                              {items.price} SAR
                                            </Typography>
                                            <Typography
                                              fontSize={"14px"}
                                              color="red"
                                              fontWeight={"500"}
                                              ml={1}
                                              sx={{
                                                marginRight:
                                                  direction === "rtl"
                                                    ? "5px"
                                                    : "unset",
                                                "@media (max-width: 320px)": {
                                                  fontSize: "12px",
                                                },
                                              }}
                                            >
                                              {items.sale_price} SAR
                                            </Typography>
                                          </Box>
                                        )}
                                        {/* <Box display="flex">
                                      <Typography
                                        fontSize={"14px"}
                                        sx={{
                                          textDecoration: "line-through",
                                          "@media (max-width: 320px)": {
                                            fontSize: "12px",
                                          },
                                        }}
                                        fontWeight={"500"}
                                      >
                                        {items.price} SAR
                                      </Typography>
                                      <Typography
                                        fontSize={"14px"}
                                        color="red"
                                        fontWeight={"500"}
                                        ml={1}
                                        sx={{
                                          marginRight:
                                            direction === "rtl" ? "5px" : "unset",
                                          "@media (max-width: 320px)": {
                                            fontSize: "12px",
                                          },
                                        }}
                                      >
                                        148 SAR
                                      </Typography>
                                    </Box> */}
                                      </Box>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          lineHeight: "23px",
                                          marginX: { xs: "30px", sm: "0px" },
                                        }}
                                      >
                                        {items.product_name}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          colort: "#000000",
                                          lineHeight: "23px",
                                          marginX: { xs: "30px", sm: "0px" },
                                        }}
                                      >
                                        {t("order.color")}: {items.color_name}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          colort: "#000000",
                                          lineHeight: "23px",
                                          marginX: { xs: "30px", sm: "0px" },
                                        }}
                                      >
                                        {t("order.size")}: {items.size}{" "}
                                        {t("order.quantity")}: {items.quantity}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              );
                            })}
                        </>
                      );
                    })}
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(
                        CurrentLanguagePath(
                          `account/orders/${order_id}/review_order`
                        )
                      )
                    }
                    sx={{ padding: "10px", mb: { xs: 1, md: 0 } }}
                  >
                    Share Your Review
                  </Button>
                </Grid>

                {/* right section */}
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "26.01px",
                    }}
                    gutterBottom
                  >
                    {t("order.payment_method")}
                  </Typography>
                  <Box
                    sx={{
                      padding: "23px",
                      marginBottom: 2,
                      backgroundColor: COLORS.WHITE,
                      borderRadius: "4px",
                    }}
                  >
                    <Typography fontSize={"14px"}>
                      {orderDetails?.payment_method}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "26.01px",
                    }}
                  >
                    {t("order.delivery_address")}
                  </Typography>
                  <Box
                    sx={{
                      padding: 2,
                      marginBottom: 2,
                      backgroundColor: COLORS.WHITE,
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "26.01px",
                      }}
                    >
                      {orderDetails?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "17.34px",
                      }}
                    >
                      {orderDetails?.delivery_address}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "17.34px",
                      }}
                    >
                      {orderDetails?.city}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "300",
                        lineHeight: "17.34px",
                      }}
                    >
                      {orderDetails?.country}
                    </Typography>
                    {orderDetails?.phone_number && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "17.34px",
                        }}
                      >
                        {formatPhoneNumber(orderDetails?.phone_number)}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    disabled={orderDetails?.is_returnable === false}
                    onClick={() => setDialogOpen(true)}
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: "0px",
                      textTransform: "capitalize",
                      mb: { xs: 2, sm: 0 },
                    }}
                  >
                    {t("order.request_return")}
                  </Button>
                  {orderDetails?.refunds &&
                    orderDetails.refunds.map((refundGroup: Refund[]) =>
                      refundGroup.map((refund: Refund) => (
                        <Box
                          sx={{
                            padding: 2,
                            marginBottom: 2,
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            mt: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#333",
                              marginBottom: "8px",
                            }}
                          >
                            Refund ID: {refund.refund_id}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#555",
                              marginBottom: "8px",
                            }}
                          >
                            Request Date:{" "}
                            {moment(refund.request_date).format("DD/MM/YYYY")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#555",
                              marginBottom: "8px",
                            }}
                          >
                            Request Time:{" "}
                            {moment(refund.request_date).format("HH:mm")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#777",
                            }}
                          >
                            Status:{" "}
                            <span
                              style={{
                                color: getStatusColor(refund.status),
                                textTransform: "capitalize",
                              }}
                            >
                              {refund.status === "on_delivery"
                                ? "On Delivery"
                                : refund.status}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() =>
                                fetchReturnItems(refund.refund_id.toString())
                              }
                              variant="contained"
                              style={{
                                padding: "2px 2px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              View
                            </Button>
                          </Box>
                        </Box>
                      ))
                    )}

                  <ProductDialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    products={orderDetails?.items || []}
                    returnReasons={returnReasons || []}
                    onSubmitSelected={returnOrder}
                  />
                  <RefundItemDialog
                    open={refundDialogOpen}
                    onClose={() => setRefundDialogOpen(false)}
                    refundProducts={(refundItems && refundItems.items) || []}
                  />
                  {/* Add additional content for the right section here */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OrderDetails;
