import { useRef, useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CardMedia,
  Rating,
  ButtonBase,
  Container,
  MenuItem,
  Select,
  FormControl,
  Modal,
  OutlinedInput,
  Divider,
} from "@mui/material";
import {
  Close,
  CloseOutlined,
  FavoriteBorder,
  FileUploadOutlined,
} from "@mui/icons-material";
import MySlider from "../../components/Slider";
import imagepath from "../../constant/imagepath";
import COLORS from "../../constant/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import {
  ColumnBox,
  CustomButton,
  FlexBox,
  StyledTypography,
} from "../../utils/theme/styles/Common";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DeliveryIcon } from "../../assets/svgIcons/delivery.svg";
import { ReactComponent as RefundIcon } from "../../assets/svgIcons/refund.svg";
import RatingChart from "../../components/RatingChart";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import GalleryContent from "../../components/GalleryContent";
import ReviewSlider from "../../components/ReviewSlider";
import { productService } from "../../api/services/productService";
import { ProductData, ProductSize } from "../../types/product";
import { wishListService } from "../../api/services/wishListService";
import ShowToast from "../../utils/ShowToast";
import useAuthStore from "../../zustand/store/user";
import { userService } from "../../api/services/userService";
import LoginDialog from "../../components/LogInDialog";
import { cartService } from "../../api/services/cartService";
import { useCartStore } from "../../zustand/store/cartData";
import { baseUrl } from "../../constant/baseUrl";
import SharePopUp from "../../components/SharePopUp";
import { CurrentLanguagePath } from "../../utils/helper";
import { useLanguageStore } from "../../zustand/store/language";
import { Helmet } from "react-helmet";

const product = {
  title: "Sparkling Bish Abaya in black",
  price: "430-SAR",
  discount: "344 SAR",
  rating: 4.5,
  color: ["Black", "red", "pink", "purple"],
  type: "Ready-to-wear",
  processingTime: "1 day",
  size: "M",
  description:
    "The Description text field provides a general description of product and extra information. Description lengths varies but usually fit within 2-4 lines or so.",
  material: "Velvet",
  cut: "Bish",
  reviews: [
    {
      reviewer: "Verified Purchaser",
      reviewDate: "about 2 months",
      reviewText: "منتج رائع وجودة ممتازة",
    },
  ],
  images: [
    imagepath.thumb1,
    imagepath.thumb2,
    imagepath.thumb3,
    imagepath.thumb4,
  ],
};

const ProductDetails = () => {
  const navigate = useNavigate();
  // const [dotActive, setDocActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReviewOpen, setisReviewOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isImageGalleryOpen, setImageGalleryOpen] = useState(false);
  const location = useLocation();
  const [dataSource, setDataSource] = useState<ProductData>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isRecentProducts, setisRecentProducts] = useState(true);
  const [isRelatedProducts, setisRelatedProducts] = useState(true);
  const selectRef = useRef<any>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSizeClose = () => {
    setOpen(false);
  };

  const handleSizeOpen = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const [personName, setPersonName] = useState<string>("");
  const handleItemClick = (item: ProductSize) => {
    setPersonName(item.product_slug);
    navigate(CurrentLanguagePath(`product-details/${item.product_slug}`), {
      replace: true,
    });
  };

  const { i18n, t } = useTranslation();
  const { getCartItems } = useCartStore();

  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const marginProp = isRTL ? "marginLeft" : "marginRight";

  const sliderRef = useRef<Carousel | null>(null);
  const thumbnailContainerRef = useRef<HTMLDivElement | null>(null);
  const sliderModalRef = useRef<Carousel | null>(null);
  const thumbnailContainerModalRef = useRef<HTMLDivElement | null>(null);
  const { language } = useLanguageStore();
  const reviews = [
    {
      rating: 4,
      text: "الفستان: يهبل ويناعم جدا وينفع حفلات مختصرة أو شي ضروري يكون على مقاسك بالضبط عشان يطلع مضبوط ووزني 65 اخذت مقاس 16 وطلع كبير جدا واخذت مقاس 14 طلع كبير اكثر لدرجه اني اخذت مقاس 12 وطلع مضبوط بس يحتاج من الوسط تصغير شوي اهم شي في هذا الفستان يكون المقاس صح والا الفستان جميل جدا",
      date: "about 2 months",
      user: "Verified Purchaser",
      avatar: "https://via.placeholder.com/40",
    },
    {
      rating: 5,
      text: "فستانللل انيق وجميل",
      date: "about 2 months",
      user: "Verified Purchaser",
      avatar: "https://via.placeholder.com/40",
    },
    {
      rating: 4,
      text: "مريييح حلو وفخم",
      date: "about 2 months",
      user: "Verified Purchaser",
      avatar: "https://via.placeholder.com/40",
    },
    {
      rating: 4,
      text: "بالنسبة للفستان جودة القماش من النوع البسيط و هو اللي أثر على جمال الفستان",
      date: "about 2 months",
      user: "Verified Purchaser",
      avatar: "https://via.placeholder.com/40",
    },
  ];

  const sliderItems = [
    {
      id: 1,
      title: "Mountain Landscape",
      subtitle: "Explore the beauty of mountains",
      buttonText: "Discover",
      imgSrc:
        "https://api.vogacloset.com/media/contentbuilder/cache/293a3c795b195ac9a89e1f3af2d39d0e/HB_-_CHIC_EID_-_WEB_-_EN.webp",
      url: imagepath.thumb1,
    },
    {
      id: 2,
      title: "Serenity Lake",
      subtitle: "Relax by the calm lake",
      buttonText: "Visit Now",
      imgSrc:
        "https://api.vogacloset.com/media/contentbuilder/cache/293a3c795b195ac9a89e1f3af2d39d0e/HB_-_TRENDING_NOW_-_WEB_-_EN.webp",
      url: imagepath.thumb2,
    },
    {
      id: 3,
      title: "Sunset at Beach",
      subtitle: "Witness the breathtaking sunset",
      buttonText: "Experience",
      url: imagepath.thumb3,
      imgSrc:
        "https://api.vogacloset.com/media/contentbuilder/cache/293a3c795b195ac9a89e1f3af2d39d0e/HB_-_HAIRCARE_-_WEB_-_EN.webp",
    },
  ];

  const afterChange = (e: any) => {
    setCurrentIndex(e);
  };

  const getSlug = () => {
    const pathname = location.pathname;
    const parts = pathname.split("/");
    const slug = parts[parts.length - 1];
    return slug;
  };
  const slug = decodeURIComponent(getSlug());
  const addToCart = async (
    productId: string,
    variantId: string,
    quantity: string
  ) => {
    if (dataSource && dataSource.sizes.length > 0 && !personName) {
      setOpen(true);
      return;
    }

    const params = {
      product_id: productId,
      product_variant_id: variantId,
      quantity: quantity,
    };

    try {
      const response: any = await cartService.addToCart(params);
      if (response && response.status === 200) {
        ShowToast(response.data.message, "success");
        await getCartItems();
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error adding to cart:", error);
    }
  };

  const addWishlist = async () => {
    if (useAuthStore.getState().isLoggedIn === false) {
      handleClickOpen();
      return;
    } else {
      setIsLoading(true);
      try {
        const params = {
          product_id: dataSource?.product_id ?? "",
          product_variant_id: dataSource?.variant_id ?? "",
        };

        const response: any = await wishListService.addToWishList(params, {
          showLoader: false,
        });
        if (response && response.status === 200) {
          ShowToast(response.message, "success");
          fetchProductDetails();
        } else {
          console.log("error message:-", response);
        }
      } catch (error: any) {
        setIsLoading(false);
        ShowToast(error.message, "error", "bottom-left");
        console.log("Error adding to wishlist:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.email}`.toLocaleLowerCase(),
      password: values.password,
    };

    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        setOpenDialog(false);
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const removeWishList = async (wishListId: number) => {
    const request = {
      wishlist_id: wishListId,
    };
    setIsLoading(true);
    try {
      const response: any = await wishListService.removeWishList(request, {
        showLoader: false,
      });

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        fetchProductDetails();
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleWishlist = () => {
    if (!isLoading) {
      if (dataSource?.wishlist_id) {
        removeWishList(Number(dataSource?.wishlist_id));
      } else {
        addWishlist();
      }
    }
  };

  const fetchProductDetails = useCallback(async () => {
    try {
      const params = { slug: slug };
      setIsLoading(true);
      const response = await productService.getProductDetails(params);
      if (response && response.status === 200) {
        const products = response.data as ProductData;
        setDataSource(products);
        if (products && !products.recently_viewed_products.length) {
          setisRecentProducts(false);
        }
        if (products && !products.related_products.length) {
          setisRelatedProducts(false);
        }
        setPersonName(products.product_slug);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchProductDetails();
  }, [fetchProductDetails, language]);

  const addToWishList = async (
    key: string,
    productId: number,
    variantId: number
  ) => {
    if (useAuthStore.getState().isLoggedIn === false) {
      handleClickOpen();
      return;
    } else {
      const request = {
        product_id: productId,
        product_variant_id: variantId,
      };
      setIsLoading(true);
      try {
        const response: any = await wishListService.addToWishList(request, {
          showLoader: false,
        });
        if (response && response.status === 200) {
          ShowToast(response.message, "success");
          const { wishlist_id } = response.data;
          let data =
            key === "related_products"
              ? dataSource?.related_products
              : dataSource?.recently_viewed_products;

          let newData =
            data &&
            data.map((product) => {
              if (
                product.product_id === productId &&
                product.product_variant_id === variantId
              ) {
                return {
                  ...product,
                  isAddedWishlist: true,
                  wishlist_id,
                };
              }
              return product;
            });

          let updateKey = {};
          if (key === "related_products") {
            updateKey = {
              related_products: newData,
            };
          } else {
            updateKey = {
              recently_viewed_products: newData,
            };
          }
          setDataSource((prevState) => {
            if (prevState) {
              return {
                ...prevState,
                ...updateKey,
              };
            }
            return prevState;
          });
        } else {
          console.log("error message:-", response);
        }
      } catch (error: any) {
        setIsLoading(false);
        ShowToast(error.message, "error", "bottom-left");
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const removeToWishList = async (
    key: string,
    wishListId: string,
    productId: number,
    variantId: number
  ) => {
    const request = {
      wishlist_id: wishListId,
    };
    setIsLoading(true);
    try {
      const response: any = await wishListService.removeWishList(request, {
        showLoader: false,
      });

      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        let data =
          key === "related_products"
            ? dataSource?.related_products
            : dataSource?.recently_viewed_products;

        let newData =
          data &&
          data.map((product) => {
            if (
              product.product_id === productId &&
              product.product_variant_id === variantId
            ) {
              return {
                ...product,
                isAddedWishlist: false,
                wishlist_id: "",
              };
            }
            return product;
          });

        let updateKey = {};
        if (key === "related_products") {
          updateKey = {
            related_products: newData,
          };
        } else {
          updateKey = {
            recently_viewed_products: newData,
          };
        }
        setDataSource((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              ...updateKey,
            };
          }
          return prevState;
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!dataSource) {
    return (
      <Container
        maxWidth={"lg"}
        sx={{
          paddingLeft: "20px !important",
          paddingRight: "20px !important",
        }}
      >
        <div style={{ height: "800px" }}></div>
      </Container>
    );
  }

  return (
    <>
      {dataSource && dataSource?.product_id ? (
        <>
          <Helmet>
            <title>{dataSource.product_title}</title>
            <meta name="title" content={dataSource.product_title} />
            <meta name="description" content={dataSource.seo.metaDescription} />
            <meta name="keywords" content={dataSource.seo.metaKeywords} />
            <meta property="og:title" content={dataSource.product_title}></meta>
            <meta property="og:description" content={dataSource.seo.metaDescription}></meta>
            <meta property="og:type" content="website"></meta>
            <meta name="description" content="__META_DESCRIPTION__" />
            <meta name="og:title" content="__META_OG_TITLE__" />
            <meta name="og:description" content="__META_OG_DESCRIPTION__" />
            <meta name="og:image" content="__META_OG_IMAGE__" />
            <meta property="og:image:width" content="300"></meta>
            <meta property="og:image:height" content="300"></meta>
            <link
              rel="canonical"
              href={
                baseUrl +
                CurrentLanguagePath(`products/${dataSource.product_slug}`)
              }
            />
          </Helmet>
          <Container
            maxWidth={"lg"}
            sx={{
              paddingLeft: "20px !important",
              paddingRight: "20px !important",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "20.23px",
                display: { xs: "none", sm: "flex" },
                mt: { xs: 0, sm: "18px" },
                ml: "2px",
                color: "#333",
                textTransform: "uppercase",
              }}
            >
              {/* {`${dataSource?.category_slug} - ${dataSource?.sub_category_slug} - `} */}
              <Link
                style={{ textDecoration: "none" }}
                to={CurrentLanguagePath(
                  `products/${dataSource?.category_slug}`
                )}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "20.23px",
                    display: { xs: "none", sm: "block" },
                    mt: { xs: 0, sm: "18px" },
                    ml: "2px",
                  }}
                  style={{ fontWeight: "300" }}
                >{`${dataSource?.category_title} - `}</Typography>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to={CurrentLanguagePath(
                  `products/${dataSource?.category_slug}?sub=${dataSource?.sub_category_slug}`
                )}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "20.23px",
                    display: { xs: "none", sm: "block" },
                    mt: { xs: 0, sm: "18px" },
                    ml: "2px",
                  }}
                  style={{ fontWeight: "300" }}
                >{`${dataSource?.sub_category_title} - `}</Typography>
              </Link>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "20.23px",
                  display: { xs: "none", sm: "block" },
                  mt: { xs: 0, sm: "18px" },
                  ml: "2px",
                  textTransform: "none",
                }}
                style={{ fontWeight: "400" }}
              >
                {dataSource?.product_title}
              </Typography>
            </Typography>
            <Grid
              container
              padding={0}
              spacing={0}
              sx={{
                mt: { xs: 0, sm: 2 },
              }}
            >
              <Grid item xs={12} sm={6} spacing={0}>
                <GalleryContent
                  sliderRef={sliderRef}
                  thumbnailContainerRef={thumbnailContainerRef}
                  afterChange={afterChange}
                  galleryImage={dataSource?.product_images || []}
                  dir={dir}
                  isRTL={isRTL}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  navigate={navigate}
                />
              </Grid>
              <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "82%",
                    backgroundColor: "white",
                    overflowY: "auto",
                    maxHeight: "90vh",
                    mt: { xs: 0, sm: "50px" },
                    position: "relative",
                  }}
                >
                  {isOpen && (
                    <IconButton
                      sx={{
                        position: "absolute",

                        [dir === "rtl" ? "left" : "right"]: "10px",
                      }}
                      color="default"
                      size="small"
                      aria-label="toggle zoom"
                      onClick={() => setIsOpen(false)}
                    >
                      <CloseOutlined />
                    </IconButton>
                  )}
                  <Container
                    maxWidth="xl"
                    sx={{
                      mt: "20px",
                      overflowY: "auto",
                      maxHeight: "100%",
                    }}
                  >
                    <GalleryContent
                      currentIndex={currentIndex}
                      sliderRef={sliderModalRef}
                      thumbnailContainerRef={thumbnailContainerModalRef}
                      galleryImage={dataSource?.product_images || []}
                      dir={dir}
                      isRTL={isRTL}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      navigate={navigate}
                    />
                  </Container>
                </Box>
              </Modal>

              <Modal open={isReviewOpen} onClose={() => setisReviewOpen(false)}>
                <Container
                  maxWidth="sm"
                  sx={{
                    bgcolor: "#fff",
                    width: { xs: "100%", sm: "70%" },
                    height: { xs: "100%", sm: "90%" },
                    backgroundColor: "white",
                    overflow: "auto",
                    mt: { xs: 0, sm: "50px" },
                    position: "relative",
                    zIndex: 6000,
                  }}
                >
                  <IconButton
                    onClick={() => setisReviewOpen(false)}
                    size="large"
                    sx={{
                      position: "absolute",
                      top: 8,
                      left: dir === "rtl" ? 8 : "unset",
                      right: dir === "rtl" ? "unset" : 8,
                      color: "black",
                    }}
                  >
                    <Close />
                  </IconButton>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                    pt={2}
                  >
                    <Typography variant="h4" component="h1" gutterBottom>
                      {t("productDetails.reviews")}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    mb={2}
                    justifyContent={"space-between"}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Rating
                        value={4.08}
                        precision={0.1}
                        readOnly
                        sx={{ color: "#da8344" }}
                      />
                      <Typography
                        variant="h6"
                        component="span"
                        ml={1}
                        fontWeight={"500"}
                      >
                        4.08
                      </Typography>
                    </div>
                    <Typography
                      variant="body2"
                      fontWeight={"500"}
                      component="p"
                      ml={1}
                    >
                      (40 {t("productDetails.reviews")})
                    </Typography>
                  </Box>
                  <RatingChart />
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "300",
                      lineHeight: "18.79px",
                      textAlign: dir === "rtl" ? "right" : "left",
                      mt: 1,
                    }}
                  >
                    {t("productDetails.reviews_info")}
                  </Typography>
                  <Divider
                    sx={{
                      height: "4px",
                      backgroundColor: "#A4A1AA33",
                      mt: "5px",
                    }}
                  />
                  {reviews?.map((review, index) => (
                    <Box
                      key={index}
                      sx={{
                        p: 2,
                        mb: 1,
                        position: "relative",
                        overflow: "auto",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={2}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Rating
                                name="read-only"
                                value={4}
                                readOnly
                                sx={{ color: "#da8344" }}
                              />
                              <StyledTypography
                                variant="body2"
                                sx={{ fontWeight: "bold", mb: 1 }}
                              >
                                Verified Purchaser
                              </StyledTypography>
                              <StyledTypography variant="body2" sx={{ mb: 1 }}>
                                ممتاز حلو وفخم
                              </StyledTypography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <StyledTypography variant="body2" sx={{ mb: 1 }}>
                                about 2 months
                              </StyledTypography>
                              <CardMedia
                                onClick={() => setImageGalleryOpen(true)}
                                draggable={false}
                                component="img"
                                height="80"
                                image={product.images[0]}
                                alt="Product image"
                                sx={{ width: "55px" }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{ height: "4px", backgroundColor: "#A4A1AA33" }}
                      />
                    </Box>
                  ))}
                  <Modal
                    open={isImageGalleryOpen}
                    onClose={() => setImageGalleryOpen(false)}
                  >
                    <Container
                      maxWidth={"sm"}
                      sx={{
                        bgcolor: "#fff",
                        width: { xs: "100%", sm: "70%" },
                        height: { xs: "100%", sm: "90%" },
                        backgroundColor: "white",
                        overflow: "auto",
                        mt: { xs: 0, sm: "50px" },
                        position: "relative",

                        zIndex: 6000,
                        padding: "20px",
                      }}
                    >
                      <IconButton
                        onClick={() => setImageGalleryOpen(false)}
                        size="large"
                        sx={{
                          position: "absolute",
                          top: 8,
                          left: dir === "rtl" ? 8 : "unset",
                          right: dir === "rtl" ? "unset" : 8,
                          color: "black",
                        }}
                      >
                        <Close />
                      </IconButton>
                      <ReviewSlider
                        sliderItems={sliderItems}
                        height={"620px"}
                      />
                    </Container>
                  </Modal>
                </Container>
              </Modal>
              <Grid item xs={12} sm={6}>
                <FlexBox
                  direction="row"
                  sx={{
                    justifyContent: { xs: "space-between", sm: "none" },
                    alignItems: "flex-start",
                    mb: 0,
                    mt: { xs: "10px", sm: "0px" },
                  }}
                >
                  <FlexBox
                    direction="row"
                    sx={{ mb: 0, pb: 0, alignItems: "flex-start" }}
                  >
                    <Typography
                      sx={{
                        mb: 0,
                        mr: 4,
                        marginRight: dir === "rtl" ? "0" : "32px",
                        marginLeft: dir === "rtl" ? "32px" : "0",

                        fontSize: { xs: "16px", sm: "32px" },
                        fontWeight: "300",
                        lineHeight: { xs: "23.12px", sm: "46.24px" },
                      }}
                    >
                      {dataSource?.product_title}
                    </Typography>
                    <IconButton
                      onClick={() => setOpenPopup(true)}
                      color="inherit"
                      size="small"
                      sx={{
                        background: "#A4A1AA33",
                        ml: 1,
                        display: { xs: "none", sm: "flex" },
                      }}
                    >
                      <FileUploadOutlined />
                    </IconButton>
                  </FlexBox>

                  <FlexBox
                    direction="row"
                    sx={{ display: { xs: "flex", sm: "none" }, mb: 0 }}
                  >
                    <IconButton
                      onClick={() => setOpenPopup(true)}
                      color="default"
                      size="small"
                      sx={{ background: "#A4A1AA33", mr: 2 }}
                    >
                      <FileUploadOutlined />
                    </IconButton>
                    <IconButton
                      color="default"
                      size="small"
                      sx={{ background: "#A4A1AA33", mr: 1 }}
                      onClick={() => handleWishlist()}
                    >
                      {!dataSource.isAddedWishlist ? (
                        <FavoriteBorder />
                      ) : (
                        <FavoriteIcon style={{ color: "red" }} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
                <Link
                  to={CurrentLanguagePath(
                    `products/brand/${dataSource?.vendor_id}`
                  )}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: { xs: "14px", sm: "24px" },
                      lineHeight: { xs: "20.23px", sm: "34.68px" },
                      mb: "5px",
                    }}
                  >
                    {dataSource?.vendor_name}
                  </Typography>
                </Link>
                <FlexBox
                  direction="row"
                  sx={{ mb: 0, display: { xs: "none", sm: "flex" } }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "30.68px",
                      textAlign: "left",
                      textDecoration: dataSource?.sale_price && "line-through",
                    }}
                  >
                    {dataSource.price} SAR
                  </Typography>

                  {dataSource.sale_price && (
                    <Typography
                      sx={{
                        marginRight: "5px",
                        color: "red ",
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "30.68px",
                        textAlign: "left",
                      }}
                    >
                      &nbsp;{dataSource?.sale_price} SAR
                    </Typography>
                  )}
                </FlexBox>
                {dataSource?.discountApplied && dataSource.showCode ? (
                  <Typography
                    variant="body1"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      mb: 1,
                      fontWeight: "300",
                      fontSize: "16px",
                    }}
                  >
                    {`${dataSource.discount} | ${t("code")}: ${
                      dataSource.coupon_code
                    }`}
                  </Typography>
                ) : dataSource?.discountApplied ? (
                  <Typography
                    variant="body1"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      mb: 1,
                      fontWeight: "300",
                      fontSize: "16px",
                    }}
                  >
                    {dataSource.discount}
                  </Typography>
                ) : (
                  dataSource.showCode && (
                    <Typography
                      variant="body1"
                      sx={{
                        display: { xs: "none", sm: "block" },
                        mb: 1,
                        fontWeight: "300",
                        fontSize: "16px",
                      }}
                    >
                      {`${t("code")}: ${dataSource.coupon_code}`}
                    </Typography>
                  )
                )}
                <FlexBox sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Rating
                    name="read-only"
                    value={4}
                    readOnly
                    sx={{ color: "#da8344" }}
                  />
                  <ButtonBase onClick={() => setisReviewOpen(true)}>
                    <StyledTypography
                      variant="body2"
                      sx={{ textDecoration: "underline" }}
                    >
                      (40 {t("productDetails.reviews")})
                    </StyledTypography>
                  </ButtonBase>
                </FlexBox>

                <FlexBox direction="row" sx={{ mb: "4px" }}>
                  <Typography
                    sx={{
                      marginRight: 1,
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {t("productDetails.color")}
                  </Typography>
                  <FlexBox direction="row" sx={{ marginBottom: 0 }}>
                    <Typography
                      sx={{
                        mr: 0.6,

                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "left",
                      }}
                    >
                      {dataSource?.color_name}
                    </Typography>
                    <ButtonBase
                      sx={{ width: 18, height: 18, borderRadius: "50%", mr: 1 }}
                    >
                      <Box
                        sx={{
                          width: 18,
                          height: 18,
                          backgroundColor: dataSource?.color_code,
                          borderRadius: "50%",
                        }}
                      />
                    </ButtonBase>
                  </FlexBox>
                </FlexBox>

                <FlexBox direction="row">
                  {dataSource?.product_variations?.map((info, index) => (
                    <ButtonBase key={index} sx={{ [isRTL ? "ml" : "mr"]: 2 }}>
                      <CardMedia
                        onClick={() =>
                          navigate(
                            CurrentLanguagePath(
                              "product-details/" + info.product_slug
                            ),
                            {
                              replace: true,
                            }
                          )
                        }
                        draggable={false}
                        component="img"
                        height="110"
                        image={info.product_image_url}
                        alt="Product image"
                        sx={{
                          width: "77px",
                          border:
                            info.product_slug === getSlug()
                              ? "2px solid #000000"
                              : "none",
                        }}
                      />
                    </ButtonBase>
                  ))}
                </FlexBox>
                <FlexBox sx={{ display: { xs: "flex", sm: "none" } }}>
                  <Rating
                    className="starColor"
                    name="read-only"
                    value={4}
                    readOnly
                    sx={{ color: "#da8344" }}
                  />
                  <ButtonBase onClick={() => setisReviewOpen(true)}>
                    <StyledTypography variant="body2">
                      (40 {t("productDetails.reviews")})
                    </StyledTypography>
                  </ButtonBase>
                </FlexBox>
                <FlexBox direction="row" sx={{ mb: 0 }}>
                  {dataSource?.type && (
                    <FlexBox direction="column" sx={{ [marginProp]: 2 }}>
                      <StyledTypography
                        variant="body1"
                        sx={{
                          fontSize: {
                            xs: "12px !important",
                            sm: "16px !important",
                          },
                        }}
                      >
                        {t("productDetails.type")}
                      </StyledTypography>
                      <ColumnBox>
                        <StyledTypography
                          variant="body2"
                          textTransform={"uppercase"}
                        >
                          {dataSource?.type}
                        </StyledTypography>
                      </ColumnBox>
                    </FlexBox>
                  )}
                  {dataSource?.processing_time && (
                    <FlexBox direction="column">
                      <StyledTypography
                        variant="body1"
                        sx={{
                          fontSize: {
                            xs: "12px !important",
                            sm: "16px !important",
                          },
                        }}
                      >
                        {t("productDetails.processing_time")}
                      </StyledTypography>
                      <ColumnBox>
                        <StyledTypography
                          variant="body2"
                          textTransform={"uppercase"}
                        >
                          {dataSource?.processing_time}
                        </StyledTypography>
                      </ColumnBox>
                    </FlexBox>
                  )}
                </FlexBox>

                <FlexBox sx={{}}>
                  {dataSource &&
                    dataSource?.sizes &&
                    dataSource?.sizes.length > 0 && (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ [marginProp]: 2, maxWidth: "220px" }}
                      >
                        <Select
                          disabled={dataSource?.sizes?.length === 1}
                          open={open}
                          onClose={handleSizeClose}
                          onOpen={handleSizeOpen}
                          input={<OutlinedInput />}
                          displayEmpty
                          value={personName}
                          // onChange={handleChange}
                          ref={selectRef}
                          sx={{
                            "& .MuiSelect-icon": {
                              left: dir === "rtl" ? "7px" : "unset",
                              right: dir === "rtl" ? "unset" : "7px",
                            },
                            "& .MuiMenuItem-root": {
                              padding: 2,
                            },
                            "& .MuiOutlinedInput-input": {
                              paddingRight: "12px !important",
                            },
                          }}
                          style={{
                            height: "35px",
                            fontWeight: "500",
                            fontStyle: "normal",
                          }}
                        >
                          <MenuItem disabled value="">
                            <span> {t("productDetails.size")}</span>
                          </MenuItem>
                          {dataSource?.sizes?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.product_slug}
                              onClick={() => handleItemClick(item)}
                            >
                              {item.size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  <FlexBox
                    direction="row"
                    sx={{ display: { xs: "none", sm: "flex" }, mb: 0 }}
                  >
                    <IconButton
                      size="small"
                      sx={{ background: "#A4A1AA33", [marginProp]: 1 }}
                      onClick={() => handleWishlist()}
                    >
                      {!dataSource.isAddedWishlist ? (
                        <FavoriteBorder />
                      ) : (
                        <FavoriteIcon style={{ color: "red" }} />
                      )}
                    </IconButton>
                    <StyledTypography
                      variant="body2"
                      fontSize="11px"
                      fontWeight="300"
                    >
                      {dataSource.isAddedWishlist
                        ? t("productDetails.in_your_wishlist")
                        : t("productDetails.add_to_wishlist")}
                    </StyledTypography>
                  </FlexBox>
                </FlexBox>

                <FlexBox sx={{ display: { xs: "none", sm: "flex" } }}>
                  <CustomButton
                    onClick={() =>
                      addToCart(
                        dataSource?.product_id.toString(),
                        dataSource.variant_id.toString(),
                        "1"
                      )
                    }
                    disableElevation
                    variant="contained"
                    color="primary"
                    sx={{
                      flexGrow: 1,
                      [marginProp]: 2,
                      height: "41px !important",
                    }}
                  >
                    {t("productDetails.add_to_bag")}
                  </CustomButton>
                  <CustomButton
                    disableElevation
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: COLORS.BLACK_ROSE,
                      width: "152px !important",
                      height: "41px !important",
                      padding: "0px !important",
                      paddingX: 7,
                      "&:hover": {
                        backgroundColor: COLORS.BLACK_ROSE,
                      },
                    }}
                    onClick={async () => {
                      await addToCart(
                        dataSource?.product_id.toString(),
                        dataSource.variant_id.toString(),
                        "1"
                      );
                      window.location.href = CurrentLanguagePath("checkout");
                    }}
                  >
                    {t("productDetails.buy_now")}
                  </CustomButton>
                </FlexBox>
                <Box sx={{}}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ flexDirection: { xs: "row", sm: "column" } }}
                  >
                    {dataSource?.estimated_delivery && (
                      <Grid item xs={6} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                          }}
                        >
                          <DeliveryIcon
                            style={{
                              flexShrink: "0",
                              height: "22px",
                              width: "30px",
                              [marginProp]: "10px",
                            }}
                          />
                          <StyledTypography
                            fontStyle={"normal"}
                            fontWeight={"300"}
                            sx={{ flexGrow: 1 }}
                            variant="body2"
                          >
                            {dataSource?.estimated_delivery}
                          </StyledTypography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={6} sm={12}>
                      <Box sx={{ display: "flex" }}>
                        <RefundIcon
                          style={{
                            width: "30px",
                            height: "22px",
                            flexShrink: "0",
                            [marginProp]: "10px",
                          }}
                        />
                        <Link to={CurrentLanguagePath("return-policy")}>
                          <StyledTypography
                            sx={{ textDecoration: "underline" }}
                            variant="body2"
                          >
                            {t("productDetails.returnPolicy")}
                          </StyledTypography>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "23.12px",
                      mb: 1,
                    }}
                  >
                    {t("productDetails.desc")}
                  </Typography>
                  {dataSource.attribute_list?.map((info, index) => (
                    <FlexBox direction="row" sx={{ mb: 0 }} key={index}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "23.12px",
                          textAlign: "left",
                        }}
                      >
                        {`${info.title}: `}&nbsp;
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          lineHeight: "23.12px",
                          textAlign: "left",
                        }}
                      >
                        {info.values}
                      </Typography>
                    </FlexBox>
                  ))}

                  <Typography
                    fontStyle={"normal"}
                    fontWeight={"300"}
                    sx={{ mt: 1, fontSize: "12px" }}
                  >
                    {HtmlParser(dataSource?.description || "")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 5, display: { xs: "none", sm: "flex" } }}>
              <Grid item xs={12} sm={6}>
                <FlexBox
                  direction="row"
                  sx={{ mb: 1, width: "42%", justifyContent: "space-between" }}
                >
                  <Typography fontWeight={"400"} fontSize={"24px"}>
                    {t("productDetails.reviews")}:
                  </Typography>
                  <Typography
                    component={"a"}
                    onClick={() => setisReviewOpen(true)}
                    style={{
                      fontWeight: 400,
                      color: COLORS.BLACK,
                      fontSize: "16px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("productDetails.viewAll")}
                  </Typography>
                </FlexBox>
                <Grid item sm={5}>
                  <Divider
                    style={{
                      width: "100%",
                      display: "flex",
                      height: 1,
                      backgroundColor: COLORS.PINK_SWAN,
                    }}
                    flexItem
                  />
                </Grid>

                <Box>
                  <FlexBox
                    direction="row"
                    sx={{ justifyContent: "space-between", mb: 2 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Rating
                        name="read-only"
                        value={5}
                        readOnly
                        sx={{ color: "#da8344" }}
                      />
                      <StyledTypography
                        variant="body2"
                        className="bold"
                        sx={{ color: "grey" }}
                      >
                        Verified Purchaser
                      </StyledTypography>
                      <StyledTypography variant="body2">
                        ممتاز حلو وفخم
                      </StyledTypography>
                    </Box>
                    <FlexBox direction="row" sx={{}}>
                      <StyledTypography
                        variant="body2"
                        sx={{ mr: 1, color: "grey" }}
                      >
                        about 2 months
                      </StyledTypography>
                      <CardMedia
                        onClick={() => setisReviewOpen(true)}
                        draggable={false}
                        component="img"
                        height="110"
                        image={product.images[0]}
                        alt="Product image"
                        sx={{ width: "77px", mr: 1, cursor: "pointer" }}
                      />
                    </FlexBox>
                  </FlexBox>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container
            maxWidth={"lg"}
            sx={{
              p: "0px !important",
              paddingX: "10px !important",

              "& .css-wsnnwg-MuiContainer-root": {
                padding: "0px !important",
              },
            }}
          >
            {isRelatedProducts && (
              <MySlider
                products={dataSource.related_products || []}
                marginTop={3}
                title={t("slider.related")}
                seeAll={false}
                handleWishlist={(index) => {
                  if (!dataSource?.related_products) {
                    return;
                  }
                  const {
                    product_id,
                    product_variant_id,
                    wishlist_id,
                    isAddedWishlist,
                  } = dataSource.related_products[index];
                  if (!isLoading) {
                    if (isAddedWishlist) {
                      removeToWishList(
                        "related_products",
                        wishlist_id,
                        product_id,
                        product_variant_id
                      );
                    } else {
                      addToWishList(
                        "related_products",
                        product_id,
                        product_variant_id
                      );
                    }
                  }
                }}
              />
            )}

            {isRecentProducts && (
              <MySlider
                products={dataSource.recently_viewed_products || []}
                marginTop={0}
                title={t("slider.recently_view")}
                seeAll={false}
                handleWishlist={(index) => {
                  if (!dataSource?.recently_viewed_products) {
                    return;
                  }
                  const {
                    product_id,
                    product_variant_id,
                    wishlist_id,
                    isAddedWishlist,
                  } = dataSource.recently_viewed_products[index];
                  if (!isLoading) {
                    if (isAddedWishlist) {
                      removeToWishList(
                        "recently_viewed_products",
                        wishlist_id,
                        product_id,
                        product_variant_id
                      );
                    } else {
                      addToWishList(
                        "recently_viewed_products",
                        product_id,
                        product_variant_id
                      );
                    }
                  }
                }}
              />
            )}

            <Box sx={{ height: { xs: "40px", sm: "0px" }, width: "100%" }} />
          </Container>
          <Box
            sx={{
              backgroundColor: "#F3F2F3",
              display: { xs: "flex", sm: "none" },
              height: "62px",
              position: "fixed",
              left: "0",
              bottom: "0",
              width: "100%",
              px: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FlexBox
              direction="column"
              sx={{
                mb: 0,
                margin: "10px",
                justifyContent: "center",
              }}
            >
              <FlexBox direction="row" sx={{ mb: 0 }}>
                <Typography
                  variant="h6"
                  sx={{
                    textDecoration: dataSource?.sale_price && "line-through",
                    mr: "6px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "26.01px",
                    textAlign: "left",
                  }}
                >
                  {dataSource.price} SAR
                </Typography>
                {dataSource.sale_price && (
                  <Typography
                    sx={{
                      color: "#E51C1C",
                      fontSize: "17px",
                      fontWeight: "400",
                      lineHeight: "26.01px",
                      textAlign: "left",
                    }}
                  >
                    &nbsp;{dataSource.sale_price} SAR
                  </Typography>
                )}
              </FlexBox>
              {dataSource.discountApplied && dataSource.showCode ? (
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "17.34px",
                    textAlign: "left",
                  }}
                >
                  {`${dataSource.discount} | ${t("code")}: ${
                    dataSource.coupon_code
                  }`}
                </Typography>
              ) : dataSource.discountApplied ? (
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "17.34px",
                    textAlign: "left",
                  }}
                >
                  {dataSource.discount}
                </Typography>
              ) : (
                dataSource.showCode && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "300",
                      lineHeight: "17.34px",
                      textAlign: "left",
                    }}
                  >
                    {` ${t("code")}: ${dataSource.coupon_code}`}
                  </Typography>
                )
              )}
            </FlexBox>
            <CustomButton
              variant="contained"
              color="primary"
              sx={{ flexGrow: 1, fontSize: "13px !important" }}
              onClick={() =>
                addToCart(
                  dataSource?.product_id.toString(),
                  dataSource.variant_id.toString(),
                  "1"
                )
              }
            >
              {t("productDetails.add_to_bag")}
            </CustomButton>
          </Box>
          <LoginDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            handleLogin={handleLoginEmail}
          />
          <SharePopUp
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            productUrl={window.location.href}
            productName={dataSource.product_title}
          />
        </>
      ) : (
        <Container
          maxWidth={"lg"}
          sx={{
            paddingLeft: "20px !important",
            paddingRight: "20px !important",
          }}
        >
          <div style={{ height: "800px" }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#555",
                fontSize: "18px",
                mt: "100px",
              }}
            >
              {t("common.pageNotFound")}
            </Typography>
          </div>
        </Container>
      )}
    </>
  );
};
export default ProductDetails;
