import axiosInstance from "../apiClient";
import API from "../endpoints";
import { AxiosRequestConfig } from "axios";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showLoader?: boolean;
}

export const wishListService = {
  getWishList: () => {
    return axiosInstance.post(API.WISHLIST);
  },

  addToWishList: (data: any, config?: CustomAxiosRequestConfig) => {
    return axiosInstance.post(API.ADD_TO_WHISHLIST, data, config);
  },

  removeWishList: (data: any, config?: CustomAxiosRequestConfig) => {
    return axiosInstance.post(API.REMOVE_WISH_LIST, data, config);
  },
};
