import { create } from "zustand";
import { useCartStore } from "./cartData";

interface UserInfo {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  isMobile: boolean;
  phone: string;
  dob: string;
  is_newsletter_subscribe: number;
  created_at: string;
  token: string;
}

interface AuthStore {
  isLoggedIn: boolean;
  isLoading: boolean;
  email: string | null;
  password: string | null;
  token: string | null;
  isMobile: boolean | null;
  rememberMe: boolean;
  userInfo: UserInfo | null;
  login: (email: string, password: string, rememberMe: boolean) => void;
  logout: () => void;
  init: () => void;
  setUserInfo: (userData: UserInfo, isLoggedIn?: boolean) => void;
  clearUserInfo: () => void;
}

const useAuthStore = create<AuthStore>((set) => ({
  isLoggedIn: false,
  email: "",
  isLoading: false,
  password: "",
  isMobile: false,
  token: "",
  rememberMe: false,
  userInfo: null,

  init: () => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const data = localStorage.getItem("userInfo") as string;
    let token = "";
    let userInfo: UserInfo | null = null;

    if (data) {
      userInfo = JSON.parse(data) as UserInfo;
      token = userInfo.token;
    }
    if ((storedEmail && storedPassword && rememberMe) || userInfo != null) {
      set({
        email: storedEmail,
        password: storedPassword,
        rememberMe,
        token: token,
        userInfo: userInfo,
        isLoggedIn: userInfo != null,
      });
    }
  },

  login: (email: string, password: string, rememberMe: boolean) => {
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    localStorage.setItem("rememberMe", rememberMe ? "true" : "false");
    set({ email, password, rememberMe, isLoggedIn: true });
  },

  setUserInfo: (userData: UserInfo, isLoggedIn?: boolean) => {
    const data = userData;
    if (!userData.token) {
      data.token = useAuthStore.getState().token || "";
    }
    useAuthStore.getState().token = data.token;

    localStorage.setItem("userInfo", JSON.stringify(data));
    set({
      token: data.token,
    });
    set({ userInfo: data, isLoggedIn: isLoggedIn ?? true });
  },
  clearUserInfo: () => {
    localStorage.removeItem("userInfo");
    set({ userInfo: null, isLoggedIn: false });
  },
  logout: () => {
    localStorage.removeItem("userInfo");
    if (!useAuthStore.getState().rememberMe) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
      useCartStore.getState().setCartData({
        cart_id: 0,
        subtotal: 0,
        grandtotal: 0,
        cart_items: [],
        recently_viewed_products: [],
      });
      set({
        token: "",
        email: "",
        password: "",
        rememberMe: false,
        isLoggedIn: false,
      });
    } else {
      set({
        isLoggedIn: false,
        token: "",
      });
      useCartStore.getState().setCartData({
        cart_id: 0,
        subtotal: 0,
        grandtotal: 0,
        cart_items: [],
        recently_viewed_products: [],
      });
    }
  },
}));

useAuthStore.getState().init();

export default useAuthStore;
