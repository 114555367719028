import SlickSlider from "../../components/SlickSlider";
import MySlider from "../../components/Slider";
import ProductSlider from "../../components/ProductSlider";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { ButtonsContainer, CustomButton } from "./styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { homeService } from "../../api/services/homeService";
import useAuthStore from "../../zustand/store/user";
import { Products } from "../../utils/type";
import { wishListService } from "../../api/services/wishListService";
import ShowToast from "../../utils/ShowToast";
import LoginDialog from "../../components/LogInDialog";
import { userService } from "../../api/services/userService";
import { useNavigate } from "react-router-dom";
import { CurrentLanguagePath } from "../../utils/helper";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../constant/baseUrl";

export interface sliderTypes {
  dataDigitalBestSeller: DataDigitalBestSeller[];
}

export interface DataDigitalBestSeller {
  id?: number | undefined;
  title?: string | undefined;
  price?: number | string | undefined;
  category?: string | undefined;
  linkImg?: string | undefined;
  sale?: number | undefined;
  name?: string;
  image?: string | undefined;
  discountPrice?: number | undefined;
  isAddedWishlist?: boolean;
  discountApplied?: boolean;
  showCode?: boolean;
}

function Home() {
  interface SliderItem {
    id: number;
    slug: string;
    src: string;
  }

  interface Brand {
    id: number;
    slug: string;
    url: string;
  }

  interface HomeData {
    title: any;
    metaDescription: string | undefined;
    metaKeywords: string | undefined;
    seo: any;
    slider?: SliderItem[];
    trending: Products[];
    brands: Brand[];
    featured: Products[];
  }

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { userInfo, email, isLogin } = useAuthStore((state) => ({
    userInfo: state.userInfo,
    email: state.email,
    isLogin: state.isLoggedIn,
  }));
  const navigate = useNavigate();
  const [homeData, setHomeData] = useState<HomeData | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isSliderLoaded, setIsSliderLoaded] = useState<boolean>(true);
  const [isTrendingLoaded, setIsTrendingLoaded] = useState<boolean>(true);
  const [isFeaturedLoaded, setIsFeaturedLoaded] = useState<boolean>(true);
  const [isBrandsLoaded, setIsBrandsLoaded] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const fetchHomeData = async () => {
    try {
      const response = await homeService.getHomeData();
      if (response.status === 200) {
        if (response && !response.data.slider.length) {
          setIsSliderLoaded(false);
        }
        if (response && !response.data.trending.length) {
          setIsTrendingLoaded(false);
        }
        if (response && !response.data.featured.length) {
          setIsFeaturedLoaded(false);
        }
        if (response && !response.data.brands.length) {
          setIsBrandsLoaded(false);
        }
        setHomeData(JSON.parse(JSON.stringify(response.data)));
      } else {
        console.log("Error fetching homeData:", response.data);
      }
    } catch (error) {
      console.log("Error fetching homeData:", error);
    }
  };

  useEffect(() => {
    fetchHomeData();
  }, [userInfo, email, isLogin]);

  if (useAuthStore.getState().isLoading || homeData === null) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flex: 1,
        }}
      ></div>
    );
  }

  const addWishList = async (
    key: string,
    productId: number,
    variantId: number
  ) => {
    if (useAuthStore.getState().isLoggedIn === false) {
      handleClickOpen();
      return;
    }

    setIsLoading(true);
    const request = {
      product_id: productId,
      product_variant_id: variantId,
    };

    try {
      const response: any = await wishListService.addToWishList(request, {
        showLoader: false,
      });
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        const { wishlist_id } = response.data;

        const updateProductInLists = (list: Products[] | undefined) => {
          return list?.map((product) => {
            if (
              product.product_id === productId &&
              product.product_variant_id === variantId
            ) {
              return {
                ...product,
                isAddedWishlist: true,
                wishlist_id,
              };
            }
            return product;
          });
        };

        setHomeData((prevState: any) => {
          if (prevState) {
            return {
              ...prevState,
              trending: updateProductInLists(prevState.trending),
              featured: updateProductInLists(prevState.featured),
            };
          }
          return prevState;
        });
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const removeWishList = async (
    key: string,
    wishListId: string,
    productId: number,
    variantId: number
  ) => {
    const request = {
      wishlist_id: wishListId,
    };

    setIsLoading(true); // Start loading

    try {
      const response: any = await wishListService.removeWishList(request, {
        showLoader: false,
      });

      if (response && response.status === 200) {
        ShowToast(response.message, "success");

        const updateProductInLists = (list: Products[] | undefined) => {
          return list?.map((product) => {
            if (
              product.product_id === productId &&
              product.product_variant_id === variantId
            ) {
              return {
                ...product,
                isAddedWishlist: false,
                wishlist_id: "",
              };
            }
            return product;
          });
        };

        setHomeData((prevState: any) => {
          if (prevState) {
            return {
              ...prevState,
              trending: updateProductInLists(prevState.trending),
              featured: updateProductInLists(prevState.featured),
            };
          }
          return prevState;
        });
      } else {
        console.log("Error message:", response);
        ShowToast("Failed to remove from wishlist", "error");
      }
    } catch (error: any) {
      setIsLoading(false);
      ShowToast(error.message, "error", "bottom-left");
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWishlist = (
    key: string,
    productId: number,
    variantId: number
  ) => {
    addWishList(key, productId, variantId);
  };

  const handleLoginEmail = async (values: any) => {
    const request = {
      email: `${values.email}`.toLocaleLowerCase(),
      password: values.password,
    };

    try {
      const response: any = await userService.signIn(request);
      const { data } = response;
      if (response && response.status === 200) {
        setOpenDialog(false);
        ShowToast(response.message, "success");
        useAuthStore.getState().setUserInfo(data);
      } else {
        console.log("error message:- ", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error.message);
    }
  };

  const handleSliderClick = (item: SliderItem) => {
    navigate(CurrentLanguagePath(`products/slider/${item.id}`));
  };

  const handleBrandClick = (item: Brand) => {
    navigate(CurrentLanguagePath(`products/brand/${item.id}`));
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ padding: isMobile ? 0 : "0 0px", overflowY: "auto" }}
    >
      {homeData && homeData.seo && (
        <Helmet>
          <link rel="canonical" href={baseUrl + CurrentLanguagePath(``)} />
          <title>{homeData.seo.title}</title>
          <meta name="description" content={homeData.seo.metaDescription} />
          <meta name="keywords" content={homeData.seo.metaKeywords} />
          <meta property="og:title" content={homeData.seo.title}></meta>
          <meta
            property="og:description"
            content={homeData.seo.metaDescription}
          ></meta>
          <meta property="og:type" content="product"></meta>
          <meta
            property="og:url"
            content={baseUrl + CurrentLanguagePath(``)}
          ></meta>
          <meta
            property="og:image"
            content={
              "https://www.lusiveksa.com//public//assets//images//brand//lusive-logo.png"
            }
          ></meta>
          <meta property="og:image:width" content="300"></meta>
          <meta property="og:image:height" content="300"></meta>
        </Helmet>
      )}

      {isSliderLoaded && (
        <SlickSlider
          sliderItems={homeData?.slider || []}
          height={isMobile ? "300px" : "290px"}
          onSliderClick={handleSliderClick}
        />
      )}
      {isTrendingLoaded && (
        <MySlider
          products={homeData?.trending || []}
          seeAll
          title="TRENDING ABAYAS"
          marginTop={3}
          slug="trending"
          handleWishlist={(index) => {
            if (!homeData?.trending) {
              return;
            }
            const {
              product_id,
              product_variant_id,
              wishlist_id,
              isAddedWishlist,
            } = homeData.trending[index];
            if (!isLoading) {
              if (isAddedWishlist) {
                removeWishList(
                  "trending",
                  wishlist_id,
                  product_id,
                  product_variant_id
                );
              } else {
                handleWishlist("trending", product_id, product_variant_id);
              }
            }
          }}
        />
      )}
      {isBrandsLoaded && (
        <ProductSlider
          brands={homeData?.brands || []}
          onBrandClick={handleBrandClick}
        />
      )}
      {isFeaturedLoaded && (
        <MySlider
          products={homeData?.featured || []}
          seeAll
          title="FEATURED"
          marginTop={0}
          slug="featured"
          handleWishlist={(index) => {
            if (!homeData?.featured) {
              return;
            }
            const {
              product_id,
              product_variant_id,
              wishlist_id,
              isAddedWishlist,
            } = homeData.featured[index];
            if (!isLoading) {
              if (isAddedWishlist) {
                removeWishList(
                  "FEATURED",
                  wishlist_id,
                  product_id,
                  product_variant_id
                );
              } else {
                handleWishlist("FEATURED", product_id, product_variant_id);
              }
            }
          }}
        />
      )}
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "500",
          mt: "20px",
        }}
      >
        {t("why_shop_with_us")}
      </Typography>
      <ButtonsContainer>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            mb={2}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CustomButton variant="outlined">{t("easy_return")}</CustomButton>
          </Grid>
          <Grid
            item
            xs={12}
            mb={2}
            sm={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CustomButton variant="outlined">
              {t("reliable_delivery")}
            </CustomButton>
          </Grid>
          <Grid
            item
            xs={12}
            mb={2}
            sm={4}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CustomButton variant="outlined">{t("time_saving")}</CustomButton>
          </Grid>
        </Grid>
      </ButtonsContainer>
      <LoginDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        handleLogin={handleLoginEmail}
      />
    </Container>
  );
}

export default Home;
