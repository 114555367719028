import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BackConatiner,
  BodyTypography,
  FormContainer,
  FormContainerWrapper,
  HeadingTypography,
  ImageContainer,
  LanguageBox,
  StyledImage,
} from "../styles/styles";
import imagepath from "../../../constant/imagepath";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomButton from "../../../components/Button";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { userService } from "../../../api/services/userService";
import ShowToast from "../../../utils/ShowToast";
import useAuthStore from "../../../zustand/store/user";
import { CurrentLanguagePath } from "../../../utils/helper";
import { useLanguageStore } from "../../../zustand/store/language";
function MobileOTP() {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  // const component = queryParams.get("from");
  const { from } = location.state || {};
  const [data, setData] = useState<any>(null);
  const { userInfo, setUserInfo } = useAuthStore();
  const { language } = useLanguageStore();
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (data) {
      if (from === "forgot") {
        navigate(CurrentLanguagePath(`resetpassword?id=${id}`));
      } else if (from === "login") {
        useAuthStore.getState().setUserInfo(data);
        navigate(CurrentLanguagePath(""));
      } else {
        setUserInfo(JSON.parse(JSON.stringify(userInfo)));
        navigate(CurrentLanguagePath(""));
      }
    }
  }, [data, from, id, navigate]);

  const handleLoginPhone = async (values: any) => {
    const request = {
      customer_id: id,
      otp: values.otp,
    };
    try {
      const response: any = await userService.verifyPhone(request);
      const { data } = response;
      if (response && response.status === 200) {
        ShowToast(response.message, "success");
        setData(response.data);
        if (from === "forgot") {
          navigate(CurrentLanguagePath(`resetpassword?id=${id}`));
        } else if (from === "login") {
          useAuthStore.getState().setUserInfo(data);
          navigate(CurrentLanguagePath(""));
        } else navigate(CurrentLanguagePath(""));
      } else {
        console.log("error message:-", response);
      }
    } catch (error: any) {
      ShowToast(error.message, "error", "bottom-left");
      console.log(error);
    }
  };

  const { setLanguage } = useLanguageStore();
  const { pathname, search: query } = useLocation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    const segments = pathname.split("/").filter(Boolean);
    const supportedLanguages = ["en", "ar"];
    if (supportedLanguages.includes(language)) {
      segments[0] = language;
    } else {
      segments.unshift(language);
    }
    const newPath = `/${segments.join("/")}${query || ""}`;
    setLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    navigate(newPath, { replace: true });
    // window.location.reload();
  };

  const validationSchema = Yup.object({
    otp: Yup.string()
      .length(5, "OTP must be exactly 5 digits")
      .required("OTP is required")
      .matches(/^\d+$/, "OTP must only contain digits"),
  });

  return (
    <Grid container sx={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <ImageContainer item xs={12} sm={12} md={7}>
        <StyledImage src={imagepath.forgotPassword} alt="Forgot Password" />
      </ImageContainer>
      <FormContainerWrapper item xs={12} sm={12} md={5}>
        <LanguageBox dir={direction} sx={{ cursor: "pointer" }}>
          <Typography onClick={() => changeLanguage("en")}>English</Typography>{" "}
          <Typography sx={{ mx: 1 }}>|</Typography>
          <Typography onClick={() => changeLanguage("ar")}>عربي</Typography>
        </LanguageBox>
        <FormContainer>
          <BackConatiner onClick={handleBack}>
            <ArrowBackIosIcon fontSize="small" className="rtl-transform" />
            <Typography
              variant="body1"
              sx={{ marginRight: direction === "rtl" ? "5px" : "unset" }}
            >
              {t("back")}
            </Typography>
          </BackConatiner>
          <HeadingTypography variant="h4">{t("enterOtp")}</HeadingTypography>
          <BodyTypography variant="body1" mb={2}>
            {t("otpDescription")}
          </BodyTypography>
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleLoginPhone(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <MuiOtpInput
                  value={values.otp}
                  onChange={(val) => setFieldValue("otp", val)}
                  length={5}
                  sx={{
                    maxWidth: "270px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "47px",
                      width: "47px",
                    },
                    "& .MuiOtpInput-TextField	": {
                      borderRadius: "0px",
                    },
                  }}
                  autoFocus
                />
                {errors.otp && touched.otp ? (
                  <div
                    style={{ color: "red", marginTop: "5px", fontSize: "12px" }}
                  >
                    {errors.otp}
                  </div>
                ) : null}
                <CustomButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{
                    mt: 2,
                    mb: 4,
                    textTransform: "capitalize",
                    borderRadius: "0px",
                    width: "322px",
                    "@media (max-width: 320px)": {
                      width: "290px",
                    },
                  }}
                >
                  {t("verify")}
                </CustomButton>
              </>
            )}
          </Formik>
        </FormContainer>
      </FormContainerWrapper>
    </Grid>
  );
}

export default MobileOTP;
