import axiosInstance from "../apiClient";
import API from "../endpoints";
import { AxiosRequestConfig } from "axios";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showLoader?: boolean;
}

export const checkoutServices = {
  checkout: (data: any) => {
    return axiosInstance.post(API.CHECKOUT, data);
  },
  placeOrder: (data: any) => {
    return axiosInstance.post(API.PLACE_ORDER, data);
  },
  getOrderDetails: (data: any) => {
    return axiosInstance.post(API.ORDER_DETAILS, data);
  },
  verifyPayment: (data: any) => {
    return axiosInstance.post(API.VERIFY_PAYMENT, data);
  },
};
