const API = {
  PROFILE: "get_profile",
  SIGNUP: "register",
  SIGNIN: "login",
  LOGOUT: "logout",
  FORGOT_PASSWORD: "forgot_password",
  RESET_PASSWORD: "reset_password",
  PHONE_LOGIN: "send_otp",
  VERIFY_PHONE: "otp_verify",
  UPDATEPROFILE: "update_profile",
  CHANGEEMAIL: "change_email",
  CHANGEPASSWORD: "change_password",
  CATEGORY_MENU: "category_menu",
  HOME: "home",
  CITY: "city",
  SHIPPING_ADDRESS: "shipping_address",
  ADD_SHIPPING_ADDRESS: "add_shipping_address",
  UPDATE_SHIPPING_ADDRESS: "update_shipping_address",
  DELETE_SHIPPING_ADDRESS: "delete_shipping_address",
  SHIPPING_ADDRESS_DETAILS: "shipping_address_details",
  PRODUCTS: "products",
  FILTER: "filter",
  PRODUCT_DETAILS: "product_details",
  WISHLIST: "wishlist",
  ADD_TO_WHISHLIST: "add_wishlist",
  REMOVE_WISH_LIST: "remove_wishlist",
  CART: "cart",
  ADD_TO_CART: "cart/add",
  REMOVE_CART: "cart/remove",
  UPDATE_CART_SIZE: "updateSize",
  APPLY_COUPON: "apply-coupon",
  RECENTLY_VIEWED_PRODUCTS: "recently_viewed_products",
  GET_BANNERS: "getBanners",
  CHECKOUT: "checkout",
  FAQ: "faq",
  PAGES: "pages",
  SETTING: "setting",
  PLACE_ORDER: "place_order",
  ORDER_DETAILS: "show_order",
  ORDERS: "orders",
  VERIFY_PAYMENT: "payment/verify-payment",
  RETURN: "return",
  SHOW_RETURNED_ITEMS: "showReturnedItems",
  REFUND_REASON: "refundReason",
  ORDER_REVIEW_ITEMS: "order_review_items",
  ORDER_REVIEW: "order_review",
};

export default API;
