import React from "react";
import { TextField, Typography } from "@mui/material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

interface AddressAutocompleteProps {
  values: { address: string };
  touched?: { address?: boolean };
  errors?: { address?: string };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (field: string) => void;
  direction: "rtl" | "ltr";
  onLocationChange: (latLng: { lat: number; lng: number }) => void;
  onCityChange: (city: string) => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  values,
  touched = {},
  errors = {},
  handleChange,
  handleBlur,
  direction,
  onLocationChange,
  onCityChange,
}) => {
  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      let city = "";
      for (let component of results[0].address_components) {
        for (let type of component.types) {
          if (type === "locality" || type === "administrative_area_level_1") {
            city = component.long_name;
            break;
          }
        }
        if (city) break;
      }
      // @ts-ignore
      handleChange({ target: { name: "address", value: address } });
      onCityChange(city);
      onLocationChange(latLng);
    } catch (error) {
      console.log("Error fetching geocode data:", error);
    }
  };
  const hasError = touched.address && !!errors.address;

  return (
    <PlacesAutocomplete
      value={values.address}
      onChange={(address: any) => {
        // @ts-ignore
        handleChange({ target: { name: "address", value: address } });
      }}
      onSelect={handleSelect}
      searchOptions={{
        // types: ["(cities)"],
        componentRestrictions: { country: "sa" },
      }}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }: any) => (
        <div>
          <TextField
            {...getInputProps({
              label: "Address (House number, Street name, Area)",
              variant: "outlined",
              fullWidth: true,
              error: hasError,
              helperText: hasError ? errors.address : undefined,
              onBlur: () => (handleBlur ? handleBlur("address") : null),
              sx: {
                borderRadius: "4px",
                "& label": {
                  transformOrigin:
                    direction === "rtl" ? "right !important" : "unset",
                  left: direction === "rtl" ? "inherit !important" : "unset",
                  right: direction === "rtl" ? "1.75rem !important" : "unset",
                  fontSize: "small",
                  color: "#000000 !important",
                  fontWeight: 400,
                  overflow: "unset",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "small",
                  color: "#000000",
                  fontWeight: 400,
                },
                "& .MuiOutlinedInput-root": {
                  height: "50px",
                  backgroundColor: "WHITE",
                  fontWeight: 400,
                  lineHeight: "17.34px",
                  "& legend": {
                    textAlign: direction === "rtl" ? "right" : "left",
                  },
                },
              },
            })}
          />
          <div
            style={{
              position: "absolute",
              width: "48%",
              zIndex: 9999999,
            }}
          >
            {/* {loading && <div>Loading...</div>} */}
            {suggestions.map((suggestion: any, index: number) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    padding: "8px",
                  }
                : {
                    backgroundColor: "#F5F5F5",
                    cursor: "pointer",
                    padding: "8px",
                  };
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <Typography>{suggestion.description}</Typography>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;
