// cartStore.ts
import { create } from "zustand";

// Define the types for cart items, payment methods, and shipping methods
interface CartItem {
  product_id: string;
  product_slug: string;
  product_name: string;
  product_variant_id: string;
  quantity: string;
  total_stock: string;
  price: string;
  sale_price: string;
  total_price: string;
  discount_label: string;
  product_image_url: string;
  color_name: string;
  color_code: string;
  size: string;
  vendor_name: string;
  sizes: { product_slug: string; size: string }[];
}

interface PaymentMethod {
  payment_method: string;
  id: number;
  name: string;
  icons: string[];
  status: boolean;
}

interface ShippingMethod {
  heading: string;
  sub_heading: string;
  shipping_method_amount: string;
}

interface CheckoutData {
  cart_id: number;
  subtotal: string;
  discountedAmount: string;
  grandtotal: string;
  taxAmount: string;
  shippingAmount: string;
  paymentMethods: PaymentMethod[];
  shippingMethods: ShippingMethod[];
  cart_items: CartItem[];
  default_shipping_price: number;
}

interface CheckoutStoreState {
  checkoutData: CheckoutData;
  coupon: string | null;
  discountMessage: string | null;
  applyCoupon: (coupon: string, message: string) => void;
  removeCoupon: () => void;
  setCheckoutData: (data: CheckoutData) => void;
}

// Create the Zustand store
const useCheckoutStore = create<CheckoutStoreState>((set) => ({
  checkoutData: {
    cart_id: 0,
    subtotal: "0",
    discountedAmount: "0",
    grandtotal: "0",
    taxAmount: "0",
    shippingAmount: "0",
    paymentMethods: [],
    shippingMethods: [],
    cart_items: [],
    default_shipping_price: 0,
  },
  coupon: null,
  discountMessage: null,
  applyCoupon: (coupon: string, message: string) =>
    set({ coupon, discountMessage: message }),
  removeCoupon: () => set({ coupon: null, discountMessage: null }),
  setCheckoutData: (data) => set({ checkoutData: data }),
}));

export default useCheckoutStore;
