import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

interface Rating {
  rating: number;
  count: number;
}

const ratings: Rating[] = [
  { rating: 5, count: 30 },
  { rating: 4, count: 5 },
  { rating: 3, count: 1 },
  { rating: 2, count: 3 },
  { rating: 1, count: 1 },
];

const totalReviews = ratings.reduce((acc, curr) => acc + curr.count, 0);
// const averageRating =
//   ratings.reduce((acc, curr) => acc + curr.rating * curr.count, 0) /
//   totalReviews;

const RatingChart: React.FC = () => {
  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      {ratings.map((item) => (
        <Box key={item.rating} display="flex" alignItems="center">
          <Typography
            variant="body1"
            style={{ minWidth: 20, fontWeight: "400" }}
          >
            {item.rating}
          </Typography>
          <LinearProgress
            className="rtl-transform"
            variant="determinate"
            value={(item.count / totalReviews) * 100}
            style={{
              flexGrow: 1,
              margin: "0 5px",
              backgroundColor: "#A4A1AA33",
              height: "8px",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default RatingChart;
