import {
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Route } from "react-router-dom";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Home from "../pages/home";
import ForgotPassword from "../pages/auth/forgotPassword";
import MobileOTP from "../pages/auth/mobileOTP";
import ProductDetails from "../pages/productDetails";
import Cart from "../pages/cart";
import Header from "../layout/Header";
import Footer from "../components/Footer";
import Checkout from "../pages/checkout";
import OrderConfirmation from "../pages/orderConfirmation";
import ProductListing from "../pages/shop";
import { useMediaQuery, useTheme } from "@mui/material";
import UserProfile from "../pages/userProfile";
import Address from "../pages/address/myAddresses";
import OrderList from "../pages/order/orderlist";
import Account from "../pages/account";
import OrderDetails from "../pages/order/orderDetails";
import NewAddress from "../pages/address/addAddress";
import FAQ from "../pages/faq";
import AboutUs from "../pages/aboutUs";
import ReturnPolicy from "../pages/returnPolicy";
import ContactUs from "../pages/contactUs";
import Wishlist from "../pages/wishlist";
import PrivateRoutes from "./ProtectedRoute";
import AuthRoutes from "./AuthRoute";
import ChangePassword from "../pages/auth/changePassword";
import { useLanguageStore } from "../zustand/store/language";
import { supportedLanguages } from "../constant/baseUrl";
import ShippingPolicy from "../pages/importantLinks/ShippingPolicy";
import PrivacyPolicy from "../pages/importantLinks/PrivacyPolicy";
import TermsConditions from "../pages/importantLinks/Terms&Conditions";
import JoinUs from "../pages/importantLinks/JoinUs";
import JoinUsSuccess from "../pages/importantLinks/JoinUs/JoinSuccess";
import { ReviewOrderPage } from "../components/ReviewOrderPage";

const Layout = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pathWithoutLang = pathname.replace(/^\/[^/]+/, "");

  return (
    <div>
      <Header />
      <Outlet />
      <ScrollRestoration />
      <div>
        {(pathWithoutLang.includes("/product-details") && isSmallScreen) ||
        pathWithoutLang === "/cart" ||
        pathWithoutLang === "/join-us" ? null : (
          <Footer />
        )}
      </div>
    </div>
  );
};

const LanguageRedirect = () => {
  const { language } = useLanguageStore();
  const { pathname } = useLocation();
  const defaultLanguage = "en";
  const segments = pathname.split("/").filter(Boolean);
  const currentLanguage = segments[0];

  if (pathname === "/") {
    return <Navigate to={`/${language}/`} />;
  }
  if (!supportedLanguages.includes(currentLanguage)) {
    const restOfPath = pathname.slice(currentLanguage.length + 1);
    const newPathname = `/${defaultLanguage}`;
    return <Navigate to={newPathname} />;
  }
  return <Outlet />;
};

const AppRoutes = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<LanguageRedirect />}>
        <Route path="/:lang">
          <Route path="/:lang" element={<Layout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="account" element={<Account />} />
              <Route path="account/profile" element={<UserProfile />} />
              <Route path="account/address" element={<Address />} />
              <Route path="account/address/new" element={<NewAddress />} />
              <Route path="account/address/edit/:id" element={<NewAddress />} />
              <Route path="account/orders" element={<OrderList />} />
              <Route
                path="account/orders/:orderId/order_details"
                element={<OrderDetails />}
              />
              <Route
                path="account/orders/:orderId/review_order"
                element={<ReviewOrderPage />}
              />

              <Route path="wishlist" element={<Wishlist />} />
            </Route>

            {/* Public Routes */}
            <Route index element={<Home />} />
            <Route
              path="product-details/:product_slug"
              element={<ProductDetails />}
            />
            <Route path="cart" element={<Cart />} />
            <Route path="products" element={<ProductListing />} />
            <Route path="products/:category" element={<ProductListing />} />
            <Route
              path="products/:category/:subcategory"
              element={<ProductListing />}
            />
            <Route path="faq" element={<FAQ />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route path="contact_us" element={<ContactUs />} />
            <Route path="shipping-policy" element={<ShippingPolicy />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="join-us" element={<JoinUs />} />
            <Route path="join-us-success" element={<JoinUsSuccess />} />
            <Route path="*" element={<Home />} />
          </Route>
          <Route element={<AuthRoutes />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="mobile-otp" element={<MobileOTP />} />
            <Route path="resetpassword" element={<ChangePassword />} />
          </Route>
          <Route path="checkout" element={<Checkout />} />
          <Route path="order-confirmation" element={<OrderConfirmation />} />
        </Route>
        <Route path="*" element={<Home />} />
      </Route>
    )
  );
};
export default AppRoutes;
