import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)({
  fontSize: 15,
  fontWeight: 400,
  textAlign: "left",
  color: "#131118",
  marginTop: 3,
  borderRadius: "2px",
  border: "0px",

  "& label.Mui-focused": {
    color: "#131118",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#131118",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#131118",
    },
    "&:hover fieldset": {
      borderColor: "#131118",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#131118",
    },
  },
  "& .MuiInputBase-input": {
    color: "#000000",
    padding: "10px 12px",
  },
  "& .MuiFormHelperText-root": {
    color: "#000000",
    marginLeft: "0px"
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: "2px",
  },
});

export default CustomTextField;
