import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Rating,
  IconButton,
  Container,
  Avatar,
} from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import ShowToast from "../utils/ShowToast";
import { ReviewData } from "../types/checkout";
import { useParams } from "react-router";
import { orderService } from "../api/services/orderService";

interface ReviewOrderPageItemProps {
  reviewItems: any;
  onReviewUpdate: (updatedItem: any) => void;
}

const ReviewOrderPageItem: React.FC<ReviewOrderPageItemProps> = (props) => {
  const { reviewItems, onReviewUpdate } = props;
  const [hoverFile, setHoverFile] = useState<string | null>(null);
  const [value, setValue] = useState<number | null>(0);
  const [description, setDescription] = useState<string>("");
  const [attachments, setAttachments] = useState<File[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setAttachments((prevFiles) => [...prevFiles, ...newFiles]);
      updateReviewWithAttachments([...attachments, ...newFiles]);
    }
  };

  const handleFileRemove = (file: File) => {
    const updatedAttachments = attachments.filter(
      (attachment) => attachment !== file
    );
    setAttachments(updatedAttachments);
    URL.revokeObjectURL(URL.createObjectURL(file));
    updateReviewWithAttachments(updatedAttachments);
  };

  const updateReviewWithAttachments = (updatedAttachments: File[]) => {
    const updatedItem = {
      product_variant_id: reviewItems.product_variant_id,
      rating: value,
      description: description,
      attachments: updatedAttachments.map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type,
      })),
    };
    onReviewUpdate(updatedItem);
  };

  const updateReview = () => {
    updateReviewWithAttachments(attachments);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
        <img
          src={reviewItems.product_image_url}
          loading="lazy"
          alt="Item"
          style={{ width: "107px", height: "159px", marginRight: "15px" }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2">
            <strong>{reviewItems.vendor_name}</strong>
          </Typography>
          <Typography>{reviewItems.product_name}</Typography>
          <Typography variant="body2">
            <strong>Color:</strong> {reviewItems.color_name} <br />
            <strong>Size:</strong> {reviewItems.size}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Rating
              name="item-rating"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                updateReview();
              }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                accept="image/*,video/*"
                multiple
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <AddPhotoAlternate sx={{ width: "35px", height: "35px" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          mt: 2,
          width: "100%",
        }}
      >
        {attachments.map((file, index) => (
          <Box
            key={index}
            onMouseEnter={() => setHoverFile(URL.createObjectURL(file))}
            onMouseLeave={() => setHoverFile(null)}
            sx={{
              position: "relative",
              width: "100px",
              height: "100px",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            {hoverFile === URL.createObjectURL(file) && (
              <CloseIcon
                onClick={() => handleFileRemove(file)}
                sx={{
                  width: "24px",
                  height: "24px",
                  border: "solid #a5a2a2 1px",
                  borderRadius: "50%",
                  padding: "2px",
                  position: "absolute",
                  right: "0px",
                  top: "-9px",
                  cursor: "pointer",
                  zIndex: "999",
                }}
              />
            )}
            {file.type.startsWith("image/") ? (
              <Avatar
                sx={{
                  objectFit: "cover",
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                  border: "solid #dadada 1px",
                  borderRadius: "10px",
                }}
                src={URL.createObjectURL(file)}
                alt={`Selected file ${index}`}
              />
            ) : (
              <video
                src={URL.createObjectURL(file)}
                controls
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  border: "solid #dadada 1px",
                  borderRadius: "10px",
                }}
              />
            )}
          </Box>
        ))}
      </Box>

      <TextField
        fullWidth
        multiline
        placeholder="Enter your comment here"
        variant="outlined"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
          updateReview();
        }}
        sx={{
          mb: 4,
          mt: 2,
          "& .MuiInputBase-root": { padding: "8px 14px !important" },
        }}
      />
    </Box>
  );
};

export const ReviewOrderPage: React.FC = () => {
  const [reviewOder, setReviewOder] = useState<ReviewData | null>(null);
  const { orderId: order_id } = useParams();
  const [rating, setRating] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [reviewItems, setReviewItems] = useState<any[]>([]);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const fetchReviewItems = useCallback(async () => {
    try {
      const response = await orderService.getOderReviewItems({
        order_id,
      });
      if (response && response.status === 200) {
        setReviewOder(response.data);
      }
    } catch (err: any) {
      ShowToast(err.message, "error");
    }
  }, [order_id]);

  useEffect(() => {
    if (order_id) {
      fetchReviewItems();
    }
  }, [order_id, fetchReviewItems]);

  const handleItemReviewUpdate = (index: number, updatedItem: any) => {
    setReviewItems((prev) => {
      const newItems = [...prev];
      newItems[index] = updatedItem;
      return newItems;
    });
  };

  const reviewOrderSubmit = async () => {
    const formData = new FormData();
    formData.append("order_id", order_id || "");
    formData.append("rating", rating?.toString() || "");
    formData.append("description", description);

    reviewItems.forEach((item, index) => {
      item.attachments.forEach((attachment: any, attachmentIndex: number) => {
        console.log(attachment, "attachment");
        formData.append(
          `items[${index}][attachments][${attachmentIndex}]`,
          attachment.url
        );
      });
      formData.append(
        `items[${index}][product_variant_id]`,
        item.product_variant_id
      );
      formData.append(`items[${index}][rating]`, item.rating);
      formData.append(`items[${index}][description]`, item.description);
    });
    try {
      const response = await orderService.orderReview(formData);
      if (response && response.status === 200) {
        console.log(response.data);
      } else {
        console.log(response.data, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container maxWidth={"sm"} sx={{ my: 5, minHeight: "100vh" }}>
      <Box sx={{ px: "5px" }}>
        <Typography variant="h6" textAlign="center" fontWeight="bold">
          Share your opinion on order #{order_id}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ mb: 3 }}>
          Get discounts and more by taking a few moments to rate your experience
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography textAlign="center" variant="h6">
            How was your shopping experience?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            fullWidth
            multiline
            placeholder="Enter your comment here"
            variant="outlined"
            sx={{
              mt: 2,
              "& .MuiInputBase-root": { padding: "8px 14px !important" },
            }}
          />
        </Box>

        <Typography variant="h6" fontWeight="bold">
          *Rate your items:
        </Typography>
        {reviewOder?.items?.map((item, index) => (
          <Box>
            <ReviewOrderPageItem
              key={index}
              reviewItems={item}
              onReviewUpdate={(updatedItem) =>
                handleItemReviewUpdate(index, updatedItem)
              }
            />
          </Box>
        ))}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={reviewOrderSubmit}
          >
            Submit Review
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
