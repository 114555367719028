import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useAuthStore from "../zustand/store/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  updateEmail: (values: any) => void;
}

const UpdateEmailDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  updateEmail,
}) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    current_email: useAuthStore.getState().userInfo?.email,
    new_email: "",
    current_password: "",
  };

  const validationSchema = Yup.object().shape({
    current_email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Email is required"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
        "Invalid email address"
      ),
    new_email: Yup.string()
      .email(t("Invalid email"))
      .required(t("New Email is required"))
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|icloud\.com|outlook\.com)$/,
        "Invalid email address"
      ),
    current_password: Yup.string()
      .required(t("Password is required"))
      .min(6, "Password must be at least 6 characters"),
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "24px 24px 0px 24px !important" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
            {t("Update Email")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => updateEmail(values)}
      >
        {({
          errors,
          touched,
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          submitForm,
        }) => (
          <Form>
            <DialogContent sx={{ padding: "0px 24px 24px 24px !important" }}>
              <Field
                focused={!!values.current_email}
                InputProps={{ readOnly: true, disableUnderline: true }}
                name="current_email"
                as={TextField}
                label={t("Email Address")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={values.current_email}
                onChange={handleChange}
                error={touched.current_email && !!errors.current_email}
                helperText={touched.current_email && errors.current_email}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
              <Field
                name="new_email"
                as={TextField}
                label={t("New Email Address")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={values.new_email}
                onChange={handleChange}
                error={touched.new_email && !!errors.new_email}
                helperText={touched.new_email && errors.new_email}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
              <Field
                name="current_password"
                as={TextField}
                label={t("Current Password")}
                variant="outlined"
                fullWidth
                margin="normal"
                value={values.current_password}
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={touched.current_password && !!errors.current_password}
                helperText={touched.current_password && errors.current_password}
                sx={{
                  borderRadius: "4px",
                  "& label": {
                    transformOrigin:
                      direction === "rtl" ? "right !important" : "unset",
                    left: direction === "rtl" ? "inherit !important" : "unset",
                    right: direction === "rtl" ? "1.75rem !important" : "unset",
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                    overflow: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "small",
                    color: "#000000",
                    fontWeight: 400,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    fontWeight: 400,
                    lineHeight: "17.34px",
                    "& legend": {
                      textAlign: direction === "rtl" ? "right" : "left",
                    },
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                fullWidth
                style={{
                  margin: "0 16px 10px 16px",
                  padding: 10,
                  textTransform: "capitalize",
                  borderRadius: "0px",
                }}
              >
                {t("Update Email")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateEmailDialog;
