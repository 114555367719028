import React from "react";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";

const SkeletonItem = styled("div")({
  minHeight: "290px",
  width: "100%",
  "@media (max-width: 800px)": {
    minHeight: "190px",
  },
});

const SliderSkeleton: React.FC<{
  numberOfItems: number;
}> = ({ numberOfItems = 1 }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {Array.from({ length: numberOfItems }).map((_, index) => (
        <SkeletonItem key={index}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </SkeletonItem>
      ))}
    </div>
  );
};

export default SliderSkeleton;
