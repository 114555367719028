import { create } from "zustand";
import { CartData } from "../../utils/type";
import { cartService } from "../../api/services/cartService";

interface CartStore {
  cartData: CartData;
  setCartData: (data: CartData) => void;
  getCartItems: (couponCode?: string) => Promise<void>;
}

export const useCartStore = create<CartStore>((set) => ({
  cartData: {
    cart_id: 0,
    subtotal: 0,
    grandtotal: 0,
    discountedAmount: 0,
    cart_items: [],
    recently_viewed_products: [],
    isRecently: true,
  },

  setCartData: (cartData: CartData) => set({ cartData }),
  getCartItems: async (couponCode?: string) => {
    try {
      const response = await cartService.getCartItems(couponCode ?? "");
      if (response && response.status === 200) {
        const data = response.data;
        const isRecently = data.recently_viewed_products.length > 0;
        set({
          cartData: { ...data, isRecently }
        });
      } else {
        console.log("Error message:", response);
      }
    } catch (error) {
      console.log("Failed to fetch cart items:", error);
    }
  },
}));
